import Container from 'components/UI/Container';
import * as S from './styles';
import { useForm } from 'react-hook-form';
import { pickBy } from 'lodash';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import arrow from '../assets/arrow.svg';
import authApi from 'api/auth';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import { useStore } from 'store';
import routes from 'routes';

const Login = () => {
  const { register, handleSubmit } = useForm({});
  const queryClient = useQueryClient();
  const { setToken, setAccount, setLoginTime } = useStore('userStore');
  const { showLoading, hideLoading } = useStore('loadingStore');
  let location = useLocation();
  let state = location.state as { from: Location };
  let from = state && state?.from?.pathname ? state?.from?.pathname : '/';
  const lang = useLang();

  const navigate = useNavigate();

  const { mutate } = useMutation(authApi.auth, {
    onSuccess: (data, values) => {
      if (data?.code === 200) {
        setToken(data.data.jwtToken);
        setAccount(data.data.userInfo);
        setLoginTime(new Date().getTime());
        setTimeout(() => {
          navigate(from, {
            replace: true,
          });
        }, 1000);
      } else {
        toast.error(data.msg);
      }
      hideLoading();
    },
    onError: () => {
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
      hideLoading();
    },
    onSettled: () => {
      queryClient.invalidateQueries('login');
    },
  });

  const onSubmit = async (values: any) => {
    showLoading();
    try {
      mutate(
        pickBy({
          ...values,
        })
      );
    } catch (e) {
      hideLoading();
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    }
  };

  const handleRegister = async (values: any) => {
    navigate(routes.register, {
      replace: true,
    });
  };

  return (
    <Container>
      <S.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContent>
            <S.Title>{lang.login.t(langKey.pages.login.login)}</S.Title>
            <S.Input>
              <S.InputPanel row>
                <S.InputLabel centerV>
                  {lang.login.t(langKey.pages.login.username)}
                </S.InputLabel>
                <S.InputCom
                  type="text"
                  placeholder=""
                  {...register('account', { required: true })}
                />
              </S.InputPanel>
              <S.Border></S.Border>
            </S.Input>
            <S.Input>
              <S.InputPanel row>
                <S.InputLabel centerV>
                  {lang.login.t(langKey.pages.login.password)}
                </S.InputLabel>
                <S.InputCom
                  type="password"
                  placeholder=""
                  {...register('password', { required: true })}
                />
                <button type={'submit'}>
                  <S.LoginImg src={arrow} alt={''}></S.LoginImg>
                </button>
              </S.InputPanel>
              <S.Border></S.Border>
            </S.Input>
            <S.SubmitPanel row>
              <S.RegisterButton onClick={handleRegister}>
                {lang.login.t(langKey.pages.login.register)}
              </S.RegisterButton>
            </S.SubmitPanel>
          </S.FormContent>
        </form>
      </S.Content>
    </Container>
  );
};

export default Login;
