export const getLevel = (name: string) => {
  if (!name) return '';
  if (name.indexOf('Founding Member') !== -1) {
    return '01';
  } else if (name.indexOf('Angel Investor') !== -1) {
    return '02';
  } else if (name.indexOf('Co-Founder') !== -1) {
    return '03';
  } else if (name.indexOf('Founder') !== -1) {
    return '04';
  }
  return '';
};

export const getPrice = (name: string) => {
  if (!name) return '';
  if (name.indexOf('Founding Member') !== -1) {
    return '10 EOS';
  } else if (name.indexOf('Angel Investor') !== -1) {
    return '100 EOS';
  } else if (name.indexOf('Co-Founder') !== -1) {
    return '1000 EOS';
  } else if (name.indexOf('Founder') !== -1) {
    return '10,000 EOS';
  }
  return '';
};

export const getMasTitle = (name: string) => {
  if (!name) return '';
  if (name.indexOf('Founding Member') !== -1) {
    return 'FOUNDING MEMBER MASK';
  } else if (name.indexOf('Angel Investor') !== -1) {
    return 'ANGEL INVESTOR MASK';
  } else if (name.indexOf('Co-Founder') !== -1) {
    return 'CO-FOUNDER MASK';
  } else if (name.indexOf('Founder') !== -1) {
    return 'FOUNDER MASK';
  }
  return '';
};

export const getAUARTitle = (name: string) => {
  if (!name) return '';
  if (name.indexOf('Co-Founder') !== -1) {
    return 'CO-FOUNDER HALO';
  } else if (name.indexOf('Founder') !== -1) {
    return 'FOUNDER AURA';
  }
  return '';
};

export const replaceSymbol = (str: string) => {
  if (!str) return '';
  return str.replace('AEOS', 'EOS');
};

export const getEditions = (name: string, maxSupply: number) => {
  if (!name) return maxSupply;
  if (name.indexOf('Co-Founder') !== -1) {
  } else if (name.indexOf('Founder') !== -1) {
    return maxSupply - 3;
  }
  return maxSupply;
};

export const isIphonex = () => {
  if (typeof window !== 'undefined' && window) {
    return (
      /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812
    );
  }
  return false;
};
