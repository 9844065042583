import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { getAUARTitle, getLevel, getMasTitle } from 'lib/utils';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import * as S from './styles';
import remarkGfm from 'remark-gfm';
import { Spin } from 'antd';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';

const List = ({
  data,
  handleInfiniteOnLoad,
  hasMore,
}: {
  data: IAsset[];
  handleInfiniteOnLoad: () => void;
  hasMore: boolean;
}) => {
  const lang = useLang();
  const renderItem = (data: IAsset, index: number) => {
    return (
      <S.RowItem key={index}>
        <S.FirstPanel>
          <S.Cover key={data.asset_id}>
            <S.ImagePanel>
              <S.InfoImage src={data.data.cover} alt={''} />
            </S.ImagePanel>
            <S.DetailPanel>
              <S.DetailLabel>
                <S.InfoLabel>
                  <S.LevelLabel>
                    {lang.productDetail.t(langKey.pages.productDetail.level) +
                      ' ' +
                      getLevel(data.name)}
                  </S.LevelLabel>
                  <S.EditionLabel>
                    {lang.profile.t(langKey.pages.profile.editions) +
                      ' ' +
                      data?.template_mint}
                  </S.EditionLabel>
                </S.InfoLabel>
              </S.DetailLabel>
              <S.DescriptionPanel>
                <S.InfoPanel>
                  <S.TitleLabel>{data.name}</S.TitleLabel>
                  <S.DescLabel>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      children={data.data.desc}
                    ></ReactMarkdown>
                  </S.DescLabel>
                </S.InfoPanel>
              </S.DescriptionPanel>
            </S.DetailPanel>
          </S.Cover>
          {data.data.image && data.data.image.length > 0 && (
            <S.DetailImagePanel>
              <S.Image src={data.data.image[0]}></S.Image>
              <S.ItemTitle>{getMasTitle(data.name)}</S.ItemTitle>
            </S.DetailImagePanel>
          )}
        </S.FirstPanel>
        {data.data.video && data.data.video.length > 0 && (
          <S.SecondPanel>
            <S.VideoPanel>
              <VideoPlayer url={data.data.video[0]}></VideoPlayer>
              <S.ItemTitle>{getAUARTitle(data.name)}</S.ItemTitle>
            </S.VideoPanel>
          </S.SecondPanel>
        )}
      </S.RowItem>
    );
  };

  return (
    <S.Scroller
      initialLoad={false}
      pageStart={1}
      threshold={250}
      loadMore={handleInfiniteOnLoad}
      hasMore={hasMore}
      useWindow={true}
      loader={
        <S.Loading center key={0}>
          <Spin indicator={<S.LoadingIcon spin />} />
        </S.Loading>
      }
    >
      <S.Grid>{data.map(renderItem)}</S.Grid>
    </S.Scroller>
  );
};

export default List;
