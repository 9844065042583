import { useQuery } from 'react-query';
import { DepositInfo, ExtendedAsset } from 'types/exchange';
import api from './index';

const routes = {
  exchangePath() {
    return '/exchange';
  },
  purchaseSalePath() {
    return [this.exchangePath(), 'purchasesale'].join('/');
  },
  withdrawPath() {
    return [this.exchangePath(), 'withdraw_token'].join('/');
  },
  getDepositPath() {
    return [this.exchangePath(), 'get_deposit_info'].join('/');
  },
};

const exchangeApi = {
  async purchaseSale(
    body: Partial<{
      buyer: string;
      buyQuantity: ExtendedAsset;
      saleId: number;
      purchaseMemo: string;
    }>
  ) {
    const { data } = await api.post(routes.purchaseSalePath(), body);
    return data;
  },

  async withdraw(
    body: Partial<{
      chainType: number;
      tokenSymbol: string;
      transferAmount: string;
      receiver: string;
      memo: string;
    }>
  ) {
    const { data } = await api.post(routes.withdrawPath(), {
      ...body,
      chainType: 0,
      transferAmount: parseInt(body.transferAmount ? body.transferAmount : '0'),
    });
    return data;
  },

  async getDepositInfo() {
    const { data } = await api.get<DepositInfo>(routes.getDepositPath());
    return data;
  },
};
export const useDepositInfo = () =>
  useQuery('getDepositInfo', exchangeApi.getDepositInfo, {
    retry: 0,
  });

export default exchangeApi;
