import apiAssets from 'api/assets';
import Container from 'components/UI/Container';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'store';
import * as S from './styles';
import { useLang } from 'i18n/i18n.constants';
import { toast } from 'react-toastify';
import { langKey } from 'i18n/i18n.valuekey';
import walletApi, { REACT_SYMBOL_CONTRACT } from 'api/wallet';
import { isEmpty } from 'lodash';
import { replaceSymbol } from 'lib/utils';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { AssetsSort } from 'atomicassets/build/API/Explorer/Enums';
import List from './List';
import logo from '../assets/Logo.png';
import Horizontal from 'components/Horizontal';

const Profile = () => {
  const [count, setCount] = useState(0);
  const { showLoading, hideLoading } = useStore('loadingStore');
  const { account } = useStore('userStore');
  const lang = useLang();
  const pageSize = 200;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [collections, setCollections] = useState([] as IAsset[]);

  const [eosBalance, setEosBalance] = useState('0');
  const [argBalance, setARGBalance] = useState('');
  const throttling = useRef(false);

  useEffect(() => {
    walletApi
      .getBalance({ symbol: 'AEOS', contract: REACT_SYMBOL_CONTRACT })
      .then((data) => {
        if (data.code === 200 && !isEmpty(data.data.balance)) {
          setEosBalance(data.data.balance.split(' ')[0]);
        }
      })
      .catch((e) => {});

    walletApi
      .getBalance({ symbol: 'ARG', contract: REACT_SYMBOL_CONTRACT })
      .then((data) => {
        if (data.code === 200 && !isEmpty(data.data.balance)) {
          setARGBalance(data.data.balance.split(' ')[0]);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    showLoading();
    apiAssets
      .countAssets({ owner: account?.username })
      .then((data) => {
        setCount(data);
      })
      .catch((e) => {
        toast.error(lang.toast.t(langKey.common.toast.requsterror));
      });
    setPage(1);
    setCollections([]);
    setHasMore(true);
    loadMore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.username, hideLoading, showLoading]);

  const loadMore = useCallback(() => {
    console.log('loadMore');
    apiAssets
      .getAssets(
        { owner: account?.username, sort: AssetsSort.Updated },
        page,
        pageSize
      )
      .then((data) => {
        if (data.length < pageSize) {
          setHasMore(false);
        }
        setCollections(collections.concat(data));
        setPage(page + 1);
        hideLoading();
      })
      .catch((e) => {
        hideLoading();
      });
  }, [account?.username, page, collections, hideLoading]);

  const handleInfiniteOnLoad = useCallback(() => {
    if (throttling.current) {
      return;
    }
    throttling.current = true;
    setTimeout(() => {
      throttling.current = false;
      loadMore();
    }, 200);
  }, [loadMore]);

  const handleToEdit = () => {
    // navigate(routes.editProfile);
  };

  return (
    <Container>
      <S.Content column>
        <S.Name onClick={handleToEdit}>
          <Horizontal text={account?.email} speed={5000} />
        </S.Name>
        {/* <S.InfoPanel row center>
          <S.Title>{lang.profile.t(langKey.pages.profile.collection)}</S.Title>
          <S.Value>{count}</S.Value>
        </S.InfoPanel> */}
        <S.TokenPanel row center>
          <S.Title>EOS</S.Title>
          <S.Value>{replaceSymbol(eosBalance)}</S.Value>
        </S.TokenPanel>
        {argBalance && (
          <S.TokenPanel row center>
            <S.Title>ARG</S.Title>
            <S.Value>{argBalance}</S.Value>
          </S.TokenPanel>
        )}
        <S.WithdrwaButton type="button">
          {lang.profile.t(langKey.pages.profile.withdraw)}
        </S.WithdrwaButton>
        <S.CollectTitle>
          {lang.profile.t(langKey.pages.profile.collection)} ({count})
        </S.CollectTitle>

        <List
          data={collections}
          handleInfiniteOnLoad={handleInfiniteOnLoad}
          hasMore={hasMore}
        />

        <S.FooterPannel>
          <S.FooterTitle
            onClick={() => (window.location.href = 'mailto:hello@ariginal.one')}
          >
            <span>{lang.home.t(langKey.pages.home.contact)}</span>
          </S.FooterTitle>
          <S.FooterTitle
            onClick={() =>
              window.open('https://www.instagram.com/ariginal.one/')
            }
          >
            <span>{lang.home.t(langKey.pages.home.social)}</span>
          </S.FooterTitle>
          <S.Logo>
            <img src={logo} alt={''}></img>
          </S.Logo>
          <S.Copyright>
            <span>{lang.home.t(langKey.pages.home.copyright)}</span>
          </S.Copyright>
        </S.FooterPannel>
      </S.Content>
    </Container>
  );
};

export default Profile;
