import React from 'react';
import { observer } from 'mobx-react-lite';
// import FadeModal from 'components/UI/FadeModal';
import { useStore } from 'store';
import SelectBuy from './SelectBuy';
import ShowDetail from './ShowDetail';
import ShowDeposit from './ShowDeposit';

const Modals = () => {
  const { modals } = useStore('modalsStore');
  return (
    <div>
      {/* <FadeModal isOpen={modals.has('selectBuy')}> */}
      {modals.has('selectBuy') && <SelectBuy />}
      {/* </FadeModal> */}
      {/* <FadeModal isOpen={modals.has('showDetail')}> */}
      {modals.has('showDetail') && <ShowDetail />}
      {/* </FadeModal> */}
      {/* <FadeModal isOpen={modals.has('showDeposit')}> */}
      {modals.has('showDeposit') && <ShowDeposit />}
      {/* </FadeModal> */}
    </div>
  );
};

export default observer(Modals);
