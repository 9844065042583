import styled from 'styled-components';

export const Container = styled.div<{
  constainer_width: number;
  duration: number;
  content_width: number;
}>`
  position: relative;
  animation: changebox ${({ duration }) => duration}s linear infinite;
  animation-play-state: running;
  animation-fill-mode: forwards;
  width: ${({ content_width }) => content_width}px;
  display: flex;
  flex-direction: row;

  &:hover {
    animation-play-state: paused;
    cursor: default;
  }
  @keyframes changebox {
    0% {
      left: ${({ content_width }) => content_width * 0}px;
    }
    100% {
      left: -${({ content_width }) => content_width * 1}px;
    }
  }
`;

export const Item = styled.p<{
  constainer_width: number;
}>`
  display: block;
  padding-left: 30px;
`;

export const StyledHorizontal = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 7.32vh;
    margin-bottom: 8.01vh;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    margin-bottom: 9.94vh;
  }
`;
