import { ExplorerApi } from '@bigblockchain/atomicmarket';

const apiMarket = new ExplorerApi(
  process.env.REACT_APP_ATOMIC_API_URL || 'https://dev.ariginal.one:9000',
  'ariginalmkts',
  {}
);

// const marketApi = {
//   async getConfig() {
//     return await apiMarket.getConfig();
//   },
//   async getSales(options: any, page: number, limit: number, data: any) {
//     return await apiMarket.getSales(options, page, limit, data);
//   },
//   async getOffers(options: any, page: number, limit: number) {
//     return await apiMarket.getOffers(options, page, limit);
//   },
//   async getBidOffers(options: any, page: number, limit: number) {
//     return await apiMarket.getBidOffers(options, page, limit);
//   },
//   async getSale(id: string) {
//     return await apiMarket.getSale(id);
//   },
//   async getSaleLogs(id: string, page: number, limit: number, order: any) {
//     return await apiMarket.getSaleLogs(id, page, limit, order);
//   },
//   async getPrices(options: any) {
//     return await apiMarket.getPrices(options);
//   },
// };

export default apiMarket;
