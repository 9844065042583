import { LoadingOutlined } from '@ant-design/icons';
import View from 'components/UI/View';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';

export const ModalContainer = styled(Modal)`
  width: 100vw;
  filter: blur(20px);
  overflow: hidden;
`;

export const Loading = styled(View)`
  width: 100vw;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
`;

export const LoadingIcon = styled(LoadingOutlined)`
  color: #ffffff;
  @media (min-width: 768px) {
    font-size: 10.625vw;
  }

  @media (max-width: 767px) {
    font-size: 10.625vh;
  }
`;
