import { namespaces } from '../i18n.constants';
import { langKey } from '../i18n.valuekey';

export const en = {
  [namespaces.common.menu]: {
    [langKey.common.menu.about]: 'About',
    [langKey.common.menu.profile]: 'Profile',
    [langKey.common.menu.home]: 'Home',
    [langKey.common.menu.deposit]: 'Deposit',
    [langKey.common.menu.menu]: 'MENU',
  },
  [namespaces.pages.home]: {
    [langKey.pages.home.title]: 'Initial NFT \nOffering',
    [langKey.pages.home.subtitle]:
      'INO (Initial NFT Offering) is a prominent crypto kick start mechanism and an innovative paradigm of rewarding its early-stage investors by granting them future tokens, NFT airdrops and privileges.\n\nINO is Ariginal’s first step to create a new ecosystem in our blockchain-powered decentralized Metaverse. The unique ecosystem incorporates the Ariginal NFT marketplace and the native token of Ariginal ARG. \n\nThe INO event has a total of ',
    [langKey.pages.home.subtitle1]: '1299 limited-edition tradable NFTs',
    [langKey.pages.home.subtitle2]:
      ' up for sale. There will be 4 different NFT tiers available for purchase, ranging from 10EOS to 10000EOS. The badge of each tier represents 4 types of funders of Ariginal.',
    [langKey.pages.home.website]: 'WEBSITE',
    [langKey.pages.home.contact]: 'CONTACT',
    [langKey.pages.home.social]: 'SOCIAL',
    [langKey.pages.home.copyright]: 'ARIGINAL 2021',
  },
  [namespaces.pages.login]: {
    [langKey.pages.login.username]: 'Username',
    [langKey.pages.login.password]: 'Password',
    [langKey.pages.login.login]: 'Login',
    [langKey.pages.login.register]: 'REGISTER',
  },
  [namespaces.pages.register]: {
    [langKey.pages.register.email]: 'Email',
    [langKey.pages.register.verify]: 'Verify',
    [langKey.pages.register.verifyCode]: 'V-Code',
    [langKey.pages.register.countdown]: 's',
    [langKey.pages.register.password]: 'Password',
    [langKey.pages.register.repeatpassword]: 'Confirm Password',
    [langKey.pages.register.register]: 'Register',
  },
  [namespaces.pages.productDetail]: {
    [langKey.pages.productDetail.selectBuyTitle]: 'Select Serial Number',
    [langKey.pages.productDetail.buy]: 'Buy',
    [langKey.pages.productDetail.selectAndBuy]: 'SELECT AND BUY',
    [langKey.pages.productDetail.confirmOrder]: 'CONFIRM AND BUY',
    [langKey.pages.productDetail.serial]: 'Serial',
    [langKey.pages.productDetail.gotocollection]: 'Go to Collection',
    [langKey.pages.productDetail.editions]: 'editions',
    [langKey.pages.productDetail.level]: 'LEVEL',
  },
  [namespaces.pages.profile]: {
    [langKey.pages.profile.collection]: 'YOUR COLLECTION',
    [langKey.pages.profile.withdraw]: 'WITHDRAW',
    [langKey.pages.profile.editions]: 'Edition',
  },
  [namespaces.pages.withdraw]: {
    [langKey.pages.withdraw.symbol]: 'Symbol',
    [langKey.pages.withdraw.amount]: 'Amount',
    [langKey.pages.withdraw.chainType]: 'Chain Type',
    [langKey.pages.withdraw.from]: 'From',
    [langKey.pages.withdraw.memo]: 'Memo',
    [langKey.pages.withdraw.receiver]: 'Receiver',
    [langKey.pages.withdraw.withdraw]: 'WITHDRAW',
  },
  [namespaces.pages.profileEdit]: {
    [langKey.pages.profileEdit.nickname]: 'Nick Name',
    [langKey.pages.profileEdit.edit]: 'Edit',
  },
  [namespaces.common.toast]: {
    [langKey.common.toast.requsterror]: 'Request error, Please retry.',
    [langKey.common.toast.pwdnotequal]:
      'Confirm password and password are not equal.',
    [langKey.common.toast.emailempty]: 'email is empty.',
    [langKey.common.toast.copysuccess]: 'Copy Success',
    [langKey.common.toast.noenoughbalance]:
      'Balance is not enough. Please deposit.',
    [langKey.common.toast.withdrawsuccess]: 'Withdraw Success',
    [langKey.common.toast.selectitem]: 'Click the item of list to select.',
  },

  [namespaces.common.modal]: {
    [langKey.common.modal.confirm]: 'Confirm',
    [langKey.common.modal.depositinfo]: 'DEPOSIT INFO',
    [langKey.common.modal.selectsearial]: 'SELECT SERIAL NUMBER',
    [langKey.common.modal.clicktobuy]: 'CLICK TO SELECT',
    [langKey.common.modal.buy]: 'BUY',
    [langKey.common.modal.receiver]: 'Receiver',
    [langKey.common.modal.copy]: 'Copy',
    [langKey.common.modal.memo]: 'Memo',
    [langKey.common.modal.limit]: 'Limit',
  },
};
