import apiAssets, { REACT_APP_HOME_COLLECTION } from 'api/assets';
import { ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import Container from 'components/UI/Container';
import { useEffect, useState } from 'react';
import { useStore } from 'store';
import * as S from './styles';
import List from './List';
import { useLang } from 'i18n/i18n.constants';
import { toast } from 'react-toastify';
import { langKey } from 'i18n/i18n.valuekey';
import logo from '../assets/Logo.png';

const Home = () => {
  const [ads, setAds] = useState([] as ITemplate[]);
  const { showLoading, hideLoading } = useStore('loadingStore');
  // const navigate = useNavigate();
  const lang = useLang();
  useEffect(() => {
    showLoading();
    apiAssets
      .getTemplates({ collection_name: REACT_APP_HOME_COLLECTION })
      .then((data) => {
        setAds(data);
        hideLoading();
      })
      .catch((e) => {
        toast.error(lang.toast.t(langKey.common.toast.requsterror));
        hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideLoading, showLoading]);

  return (
    <Container>
      <S.Content>
        <S.TitlePanel>
          <S.Title>
            <span>{lang.home.t(langKey.pages.home.title)}</span>
          </S.Title>
          <S.SubTitle>
            <p>
              {lang.home.t(langKey.pages.home.subtitle)}
              <span>{lang.home.t(langKey.pages.home.subtitle1)}</span>
              {lang.home.t(langKey.pages.home.subtitle2)}
            </p>
          </S.SubTitle>
        </S.TitlePanel>
        <List data={ads} />
        <S.FooterPannel>
          <S.FooterTitle
            onClick={() => (window.location.href = 'mailto:hello@ariginal.one')}
          >
            <span>{lang.home.t(langKey.pages.home.contact)}</span>
          </S.FooterTitle>
          <S.FooterTitle
            onClick={() =>
              window.open('https://www.instagram.com/ariginal.one/')
            }
          >
            <span>{lang.home.t(langKey.pages.home.social)}</span>
          </S.FooterTitle>
          <S.Logo>
            <img src={logo} alt={''}></img>
          </S.Logo>
          <S.Copyright>
            <span>{lang.home.t(langKey.pages.home.copyright)}</span>
          </S.Copyright>
        </S.FooterPannel>
      </S.Content>
    </Container>
  );
};

export default Home;
