import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import View from '../UI/View';

export const HeaderContainer = styled(View)``;

export const MenuTitle = styled.div`
  cursor: pointer;
  font-weight: 700;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    margin-right: 2.78vw;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    margin-right: 4.8vw;
  }
  opacity: 0.5;
`;

export const MenuFold = styled(Icon)`
  opacity: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    img {
      height: 5.47vh;
    }
  }

  @media (max-width: 767px) {
    img {
      height: 4.59vh;
    }
  }
`;

export const UnfoldView = styled(View)`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 5.47vh;
  }

  @media (max-width: 767px) {
    height: 4.59vh;
  }
`;

export const MenuUnfold = styled(Icon)`
  opacity: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    img {
      width: 1.25vw;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 4.8vw;
    }
  }
`;

export const HeaderView = styled(View)`
  background-color: #000;
  @media (min-width: 768px) {
    padding-top: 3.9vh;
    padding-left: 2.78vw;
    padding-right: 2.78vw;
  }

  @media (max-width: 767px) {
    padding-top: 2.04vh;
    padding-left: 4.27vw;
    padding-right: 4.27vw;
  }
`;

export const LogoImg = styled.img`
  cursor: pointer;
  width: 100%;
  @media (min-width: 768px) {
    width: 5.14vw;
  }

  @media (max-width: 767px) {
    width: 19.73vw;
  }
`;

export const MenuContainer = styled(Modal)`
  width: 100vw;
`;

MenuContainer.defaultProps = {
  style: {
    border: 'none',
    borderRadius: 0,
  },
};

export const MenuPanel = styled(View)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const MenuView = styled(View)`
  width: 100%;
  height: 100%;
  background-color: #000;
  @media (min-width: 768px) {
    padding-top: 6.54vh;
  }

  @media (max-width: 767px) {
    padding-top: 23.44vh;
  }
`;

export const MenuCotainer = styled(Menu)``;

export const MenuItem = styled(Menu.Item)`
  width: 100vw;
  text-align: center;
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 14.65vh;
    padding: 1.1vh 0;
  }

  @media (max-width: 767px) {
    font-size: 8.15vh;
    padding: 0.89vh 0;
  }
`;

export const MenuItemLogout = styled(Menu.Item)`
  width: 100vw;
  text-align: center;
  position: fixed;
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 1.46vh;
    bottom: 6.35vh;
  }

  @media (max-width: 767px) {
    font-size: 1.91vh;
    bottom: 5.61vh;
  }
`;
