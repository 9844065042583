import View from 'components/UI/View';
import styled from 'styled-components';

export const Content = styled(View)`
  opacity: 1;

  @media (min-width: 768px) {
    text-align: center;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    padding-top: 10.94vh;
  }

  @media (max-width: 767px) {
    height: 100%;
    text-align: left;
    display: flex;
    display: -webkit-flex;
    justifycontent: center;
    padding-top: 1.78vh;
  }
`;
export const Title = styled.div`
  font-weight: 400;
  opacity: 1;

  @media (min-width: 768px) {
    font-size: 14.65vh;
    margin-bottom: 8.4vh;
    padding-top: 1.17vh;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    margin-left: 4.27vw;
    margin-bottom: 3.82vh;
  }
`;

export const Input = styled.label`
  display: block;
  text-align: left;
  input:-internal-autofill-previewed,
  input:-internal-autofill-selected {
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  opacity: 0.5;

  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    width: 100vw;
    padding-left: 4.27vw;
    padding-right: 4.27vw;
  }
`;

export const Border = styled.div`
  background-color: #fff;
  height: 1px;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const InputLabel = styled(View)`
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 1.46vh;
    padding: 0.39vh 0;
  }

  @media (max-width: 767px) {
    font-size: 1.91vh;
    padding: 0.51vh 0;
  }
`;

export const InputCom = styled.input`
  flex: 1;
  border: none;
  background: none;
  color: #fff;
  font-weight: 400;
  @media (min-width: 768px) {
    font-size: 1.46vh;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
  }

  @media (max-width: 767px) {
    margin-left: 0.64vh;
    padding-right: 0.64vh;
  }
`;

export const FormContent = styled(View)``;

export const InputPanel = styled(View)`
  @media (min-width: 768px) {
    padding-top: 2.54vh;
    padding-bottom: 1.27vh;
  }

  @media (max-width: 767px) {
    padding-top: 3.31vh;
    padding-bottom: 1.66vh;
  }
`;

export const SubmitButton = styled.button`
  background: #ffffff;
  font-weight: 400;
  opacity: 1;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    padding: 0.97vw 4.17vw;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    padding: 1.78vh 7.64vh;
  }
`;

export const SubmitPanel = styled(View)`
  @media (min-width: 768px) {
    margin-top: 4.1vh;
  }

  @media (max-width: 767px) {
    margin-top: 5.35vh;
  }
`;
