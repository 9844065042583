import View from 'components/UI/View';
import styled from 'styled-components';

export const Content = styled(View)`
  opacity: 1;
  height: 100%;

  @media (min-width: 768px) {
    text-align: center;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    padding-top: 21.29vh;
  }

  @media (max-width: 767px) {
    text-align: left;
    display: flex;
    display: -webkit-flex;
    justifycontent: center;
    padding-top: 22.42vh;
  }
`;

export const Title = styled.div`
  font-weight: 400;
  opacity: 1;

  @media (min-width: 768px) {
    font-size: 14.65vh;
    margin-bottom: 8.98vh;
    padding-top: 1.17vh;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    margin-left: 4.27vw;
    margin-bottom: 6.11vh;
  }
`;

export const Input = styled.label`
  display: block;
  text-align: left;
  input:-internal-autofill-previewed,
  input:-internal-autofill-selected {
    -webkit-text-fill-color: #ffffff !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  opacity: 0.5;

  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    width: 100vw;
    padding-left: 4.27vw;
    padding-right: 4.27vw;
  }
`;

export const Border = styled.div`
  background-color: #fff;
  height: 1px;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const InputLabel = styled(View)`
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    font-size: 1.46vh;
  }

  @media (max-width: 767px) {
    font-size: 1.91vh;
    width: 10.19vh;
  }
`;

export const InputCom = styled.input`
  flex: 1;
  border: none;
  background: none;
  color: #fff;
  font-weight: 400;
  @media (min-width: 768px) {
    font-size: 1.46vh;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
  }

  @media (max-width: 767px) {
    margin-left: 0.64vh;
    padding-right: 0.64vh;
  }
`;

export const FormContent = styled(View)`
  height: 100%;
  position: relative;
`;

export const InputPanel = styled(View)`
  @media (min-width: 768px) {
    padding-top: 2.05vh;
    padding-bottom: 1.76vh;
  }

  @media (max-width: 767px) {
    padding-top: 2.68vh;
    padding-bottom: 2.04vh;
  }
`;

export const LoginImg = styled.img`
  @media (min-width: 768px) {
    width: 1.6vw;
  }

  @media (max-width: 767px) {
    width: 2.93vh;
  }
`;

export const SubmitButton = styled.button`
  font-weight: 500;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  flex: 1;
  @media (min-width: 768px) {
    font-size: 2.25vh;
    padding: 0.625vw 2.65vw;
    margin: 0 2.65vw;
  }

  @media (max-width: 767px) {
    font-size: 2.93vh;
  }
`;

export const RegisterButton = styled.button`
  opacity: 1;
  font-weight: 500;
  color: #fff;
  flex: 1;
  @media (min-width: 768px) {
    font-size: 1.46vh;
  }

  @media (max-width: 767px) {
    font-size: 1.91vh;
  }
`;

export const SubmitPanel = styled(View)`
  position: absolute;
  width: 100%;
  @media (min-width: 768px) {
    bottom: 5.86vh;
  }

  @media (max-width: 767px) {
    bottom: 10.45vh;
  }
`;
