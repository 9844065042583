import styled from 'styled-components';
export const VerifyButton = styled.button<{ start: number }>`
  opacity: ${({ start = 0 }) => (start ? '0.8' : '1')};
  text-align: center;
  color: #fff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  @media (min-width: 768px) {
    font-size: 1.46vh;
    padding: 0.59vh 1.53vw;
    min-width: 5.14vw;
  }

  @media (max-width: 767px) {
    font-size: 1.91vh;
    padding: 0.76vh 5.86vw;
    min-width: 22.4vw;
  }
`;
