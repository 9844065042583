import { createRef, useCallback, useState } from 'react';
import * as S from './styles';
import player from './assets/playbutton.png';

const VideoPlayer = (props: any) => {
  const { url } = props;
  const video = createRef<HTMLVideoElement>();
  const [showPlay, setShowPlay] = useState(true);

  const handleVideoClick = useCallback(() => {
    console.log('handleVideoClick');
    if (!video.current) return;

    video.current.pause();
    var playPromise = video.current.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Automatic playback started!
          // Show playing UI.
          setShowPlay(false);
        })
        .catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
          setShowPlay(true);
        });
    }
  }, [video]);

  const handleVideoEnded = (data: any) => {
    setShowPlay(true);
  };

  return (
    <S.Video>
      <video
        src={url}
        // poster={poster}
        // controls
        style={{
          width: '100%',
          minHeight: '100%',
          objectFit: 'cover',
        }}
        playsInline
        loop
        autoPlay
        preload="load"
        // airplay='allow'
        // x5-playsinline='true'
        // x-webkit-airplay='allow'
        x5-video-player-type="h5"
        // webkit-playsinline='true'
        disablePictureInPicture
        disableRemotePlayback
        ref={video}
        onError={handleVideoEnded}
        // onEnded={handleVideoEnded}
        onLoadedData={handleVideoClick}
      />
      {showPlay && (
        <S.PlayContainer>
          <S.VideoMask></S.VideoMask>
          <S.ImageContainer onClick={handleVideoClick}>
            <S.PlayButton src={player} alt={''} />
          </S.ImageContainer>
        </S.PlayContainer>
      )}
    </S.Video>
  );
};

export default VideoPlayer;
