import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Layout } from 'antd';
import Home from 'pages/Home';
import { GlobalStyles } from 'globalStyles';
import routes from './routes';
import ScrollToTop from 'components/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RootStore, StoreContext, useStore } from 'store';
import Modals from 'components/Modals';
import { AuthContext } from 'router/authProvider';
import Login from 'pages/Login';
import Register from 'pages/Reigster';
// import GlobalHeader from 'components/Header';
import Loading from 'components/Loading';
import ProductDetail from 'pages/ProductDetail';
import Profile from 'pages/Profile';
import AssetDetail from 'pages/AssetDetail';
import moment from 'moment';
// import { useWindowSize } from 'react-use';
// import Withdraw from 'pages/Withdraw';
// import EditProfile from 'pages/EditProfile';
const queryClient = new QueryClient();

export const store = new RootStore();

function App() {
  const { Content } = Layout;
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const { token, loginTime, reset } = useStore('userStore');
    const location = useLocation();
    let isExpiration = false;

    if (loginTime) {
      const hours = moment(new Date().getTime()).diff(
        moment(parseInt(loginTime)),
        'hours'
      );
      if (hours >= 23) {
        isExpiration = true;
      }
    } else {
      isExpiration = true;
    }

    if (!token || isExpiration) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      reset();
      return <Navigate to={routes.login} state={{ from: location }} />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <StoreContext.Provider value={store}>
        <AuthContext.Provider
          value={{
            jwtToken: store.userStore.token,
            setToken: store.userStore.setToken,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <ScrollToTop />
            <Layout>
              <Content style={{ flex: 1 }}>
                <Routes>
                  <Route path={routes.login} element={<Login />} />
                  <Route path={routes.register} element={<Register />} />
                  <Route
                    path={routes.home}
                    element={
                      <RequireAuth>
                        <Home />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={routes.productDetail}
                    element={
                      <RequireAuth>
                        <ProductDetail />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={routes.profile}
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={routes.assetDetail}
                    element={
                      <RequireAuth>
                        <AssetDetail />
                      </RequireAuth>
                    }
                  />
                  {/* <Route
                    path={routes.withdraw}
                    element={
                      <RequireAuth>
                        <Withdraw />
                      </RequireAuth>
                    }
                  /> */}
                  {/* <Route
                    path={routes.editProfile}
                    element={
                      <RequireAuth>
                        <EditProfile />
                      </RequireAuth>
                    }
                  /> */}
                </Routes>
              </Content>
              <Modals />
              <ToastContainer position="bottom-center" />
              <Loading></Loading>
            </Layout>
          </QueryClientProvider>
        </AuthContext.Provider>
      </StoreContext.Provider>
    </BrowserRouter>
  );
}

export default App;
