import React, { ReactNode, useCallback, useRef } from 'react';
import useOnCloseByEsc from 'hooks/useOnCloseByEsc';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
export interface ModalProps {
  children: ReactNode;
  onClose?: () => void;
  lockScroll?: boolean;
  closeOnEsc?: boolean;
  width?: string;
  height?: string;
  style?: any;
}

export const Wrapper = styled.div<{ innerHeight?: string }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: ${({ innerHeight = '100vh' }) => innerHeight};
  min-height: ${({ innerHeight = '100vh' }) => innerHeight};
  display: flex;
  overflow: hidden;
  z-index: 1000;
  & > div {
    display: flex;
    margin: 0 auto;
  }
`;

export const Inner = styled.div<{ $width?: string; $height?: string }>`
  width: 100%;
  max-width: ${({ $width = '456px' }) => $width};
  height: ${({ $height = '100vh' }) => $height};
  height: -webkit-fill-available;
`;

export const Dialog = styled.div<{ innerHeight?: string }>`
  margin: auto;
  width: 100%;
  height: ${({ innerHeight = '100%' }) => innerHeight};
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  align-self: flex-start;
`;

const ModalContainer = ({
  children,
  lockScroll,
  onClose,
  width,
  height,
  style,
}: ModalProps) => {
  const shouldClose = useRef<boolean | null>(null);
  useLockBodyScroll({ lockScroll });
  const content = useRef<HTMLDivElement>(null);
  const handleOverlayClick = useCallback(() => {
    if (shouldClose.current === null) {
      shouldClose.current = true;
    }
    if (shouldClose.current) {
      if (onClose) onClose();
    }
    shouldClose.current = null;
  }, [onClose]);
  const [handleKeyDown] = useOnCloseByEsc(content, onClose);
  const handleContentOnClick = () => {
    shouldClose.current = false;
  };

  const handleContentOnMouseDown = () => {
    shouldClose.current = false;
  };
  const handleContentOnMouseUp = () => {
    shouldClose.current = false;
  };

  return (
    <Wrapper onClick={handleOverlayClick} innerHeight={height}>
      <Inner
        $width={width}
        $height={height}
        ref={content}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        onMouseDown={handleContentOnMouseDown}
        onMouseUp={handleContentOnMouseUp}
        onClick={handleContentOnClick}
        style={style}
      >
        {children}
      </Inner>
    </Wrapper>
  );
};

const Modal = ({
  lockScroll,
  onClose,
  children,
  width = '100vw',
  style,
}: ModalProps) => {
  const { height: innerHeight } = useWindowSize();
  return (
    <ModalContainer width={width} height={innerHeight + 'px'} onClose={onClose}>
      <Dialog style={style} innerHeight={innerHeight + 'px'}>
        {children}
      </Dialog>
    </ModalContainer>
  );
};

export default Modal;
