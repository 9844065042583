const routes = {
  home: '/',
  login: '/login',
  register: '/register',
  productDetail: '/product-detail/:collection/:template',
  assetDetail: '/asset-detail',
  profile: '/profile',
  withdraw: '/withdraw',
  about: '/about',
};

export default routes;
