import View from 'components/UI/View';
import styled from 'styled-components';

export const Content = styled(View)`
  width: 100vw;
  font-size: 3.2vw;
  font-weight: bold;
  line-height: 4.8vw;
  opacity: 1;
`;

export const LandingImg = styled.img`
  width: 15.46vw;
  margin-top: 7.34vw;
`;

export const Title = styled.div`
  font-size: 6.04vw;
  font-weight: 500;
  color: #1a1a1a;
  opacity: 1;
  margin: 0 14.01vw 1.21vw 0;
  overflow-x: auto;
  white-space: nowrap;
  line-height: 8.45vw;
  max-width: calc(100vw - 90px);
`;

export const Description = styled.div`
  font-size: 2.9vw;
  color: #1a1a1a;
  white-space: pre-line;
  overflow-y: auto;
  max-height: calc(32.5vh - 150px);
  padding-right: 7.73vw;
`;

export const SubmitButton = styled.button`
  background: #1a1a1a;
  border-radius: 0.97vw;
  font-size: 2.25vh;
  font-weight: 500;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  padding: 1.625vw 2.65vw;
  margin-top: 4.83vw;
  flex: 1;
`;

export const TopScroller = styled.div`
  position: fixed;
  top: 0;
  overflow: auto;
  max-height: 30.5vh;
  width: 100%;
  padding-left: 7.73vw;
  margin-top: 5.8vw;
`;

export const Scroller = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: auto;
  max-height: 67.5vh;
`;

export const Grid = styled.ul<{ panel: number }>`
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 100vw));
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
