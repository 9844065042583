import View from 'components/UI/View';
import styled from 'styled-components';

export const Content = styled(View)`
  opacity: 1;
  height: 100%;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const ContentMobile = styled(View)`
  width: 100vw;
  opacity: 1;
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 767px) {
  }
`;

export const TopPanel = styled(View)``;

TopPanel.defaultProps = {
  row: true,
};

export const FirstPanel = styled.div`
  @media (min-width: 768px) {
    width: 22.92vw;
    padding-left: 2.78vw;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
  }

  @media (max-width: 767px) {
    position: relative;
    // margin: 4.08vh 0;
  }
`;

export const GoButton = styled.div`
  @media (min-width: 768px) {
    margin-top: 3.91vh;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    text-align: left;
    justify-content: flex-start;
    margin-left: 4.27vw;
    margin-top: 1.02vh;
  }
`;

export const GoImg = styled.img`
  transform: rotate(180deg);
  @media (min-width: 768px) {
    width: 1.875vw;
  }

  @media (max-width: 767px) {
    width: 8vw;
  }
`;

export const InfoPanel = styled.div`
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    width: 100%;
    z-index: 99;
    position: absolute;
    bottom: 30.7vh;
    padding: 0 4.27vw;
  }
`;

export const InfoLabel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 1.17vh;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    font-size: 1.53vh;
  }
`;
export const LevelLabel = styled.div`
  opacity: 0.5;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;
export const EditionLabel = styled.div`
  @media (min-width: 768px) {
    margin-left: 1.11vw;
  }

  @media (max-width: 767px) {
    margin-left: 2.67vw;
  }
`;

export const PriceLabel = styled.div`
  @media (min-width: 768px) {
    margin-left: 1.11vw;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    margin-left: 3.2vw;
    font-weight: 600;
  }
`;

export const CoverPanel = styled.div`
  @media (min-width: 768px) {
    flex: 1;
    min-height: 69.14vh;
    img {
      width: 100%;
      min-height: 69.14vh;
      object-fit: cover;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 100%;
      min-height: 87.9vh;
      object-fit: cover;
    }
  }
`;

export const Title = styled.div`
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 2.22vw;
    margin-top: 2.34vh;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    margin-top: 2.04vh;
  }
`;

export const SecondPanel = styled(View)`
  @media (min-width: 768px) {
    width: 22.92vw;
    padding-right: 2.29vw;
    padding-left: 2.78vw;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  @media (max-width: 767px) {
    min-height: 67.39vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

export const Description = styled.div`
  white-space: pre-line;
  opacity: 0.75;
  ul {
    list-style: disc;
    list-style-position: inside;
    font-size: 0;
    li {
      @media (min-width: 768px) {
        line-height: 1.56vh;
        font-size: 1.17vh;
        text-indent: -17px;
        padding-left: 24px;
      }

      @media (max-width: 767px) {
        line-height: 2.04vh;
        font-size: 1.53vh;
        text-indent: -17px;
        padding-left: 24px;
      }
    }
  }
  @media (min-width: 768px) {
    font-size: 1.17vh;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    padding: 5.1vh 0 5.1vh 4.27vw;
    opacity: 0.75;
  }
`;

export const Border = styled.div`
  background-color: #fff;
  height: 1px;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const SubmitButton = styled.button`
  font-weight: 400;
  color: #fff;
  opacity: 1;
  border: 1px solid #fff;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    padding: 1.76vh 2.29vw;
    margin-top: 3.91vh;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    margin-top: 3.82vh;
    padding: 2.29vh 8.8vw;
  }
`;

export const Grid = styled.ul<{ size: number }>`
  display: grid;

  border-top: 1px solid #fff;
  @media (min-width: 768px) {
    grid-template-columns: repeat(
      ${({ size }) => size},
      minmax(auto, ${({ size }) => 100 / size + 'vw'})
    );
    li {
      display: flex;
      align-items: center;
      position: relative;
      height: 75.2vh;
      border-bottom: 1px solid #fff;
      &:nth-child(odd) {
        border-right: ${({ size }) => (size > 1 ? '1px' : '0')} solid #fff;
      }
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(auto, 100vw));
    li {
      display: flex;
      align-items: center;
      height: 67.39vh;
      position: relative;
      border-bottom: 1px solid #fff;
    }
  }
`;

export const Image = styled.img`
  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 67.39vh;
    object-fit: cover;
  }
`;

export const ItemTitle = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    bottom: 3.91vh;
    font-size: 1.17vh;
    left: 2.78vw;
  }

  @media (max-width: 767px) {
    position: absolute;
    bottom: 2.04vh;
    font-size: 1.53vh;
    left: 4.27vw;
  }
`;

export const FooterPannel = styled(View)`
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 7.32vh;
    margin-bottom: 7.03vh;
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
  }
`;

export const FooterLabel = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 14.65vh;
  }

  @media (max-width: 767px) {
    font-size: 8.15vh;
  }
`;

export const Logo = styled.div`
  @media (min-width: 768px) {
    margin-top: 5.86vh;
    img {
      width: 3.89vw;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
    img {
      height: 7.13vh;
    }
  }
`;

export const Copyright = styled.div`
  @media (min-width: 768px) {
    margin-top: 2.85vw;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 2.04vh;
    margin-top: 5.1vh;
    padding-bottom: 8.66vh;
  }
`;
