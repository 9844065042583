import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import React from 'react';
import { DetailInfo } from 'types/ino';
import * as S from './styles';

const List = ({ data }: { data: DetailInfo[] }) => {
  const renderItem = (data: DetailInfo, index: number) => {
    return (
      <li key={index}>
        {data.type === 'image' && <S.Image src={data.url} alt={''} />}
        {data.type === 'video' && <VideoPlayer url={data.url} />}
        {data.title && <S.ItemTitle>{data.title}</S.ItemTitle>}
      </li>
    );
  };

  return <S.Grid size={data.length}>{data.map(renderItem)}</S.Grid>;
};

export default List;
