import { createContext, useContext } from 'react';

import ModalStore from 'store/modal';
import LoadingStore from './loading';
import UserStore from './user';

export class RootStore {
  modalsStore: ModalStore;
  userStore: UserStore;
  loadingStore: LoadingStore;

  constructor() {
    this.modalsStore = new ModalStore();
    this.userStore = new UserStore();
    this.loadingStore = new LoadingStore();
  }
}

export const StoreContext = createContext<RootStore>({} as RootStore);
export const useStores = (): RootStore => useContext(StoreContext);
export const useStore = <T extends keyof RootStore>(store: T): RootStore[T] =>
  useContext(StoreContext)[store];
