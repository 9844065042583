import { namespaces } from '../i18n.constants';
import { langKey } from '../i18n.valuekey';
export const zhCN = {
  [namespaces.common.menu]: {
    [langKey.common.menu.about]: '关于',
    [langKey.common.menu.profile]: '个人中心',
    [langKey.common.menu.home]: '首页',
    [langKey.common.menu.deposit]: '充值',
    [langKey.common.menu.menu]: '菜单',
  },
  [namespaces.pages.home]: {
    [langKey.pages.home.title]: '首次NFT \n发行',
    [langKey.pages.home.subtitle]:
      ' INO（初始 NFT 发行）通过平台代币、NFT 空投和其他特权来奖励早期投资者。\n\nINO 是 Ariginal 在区块链驱动的去中心化 Metaverse中构建创新生态的第一步。Ariginal独特的生态系统结合了NFT 市场和 Ariginal ARG 的原生代币。 \n\n本次INO 共计发行',
    [langKey.pages.home.subtitle1]: '限量1299份可交易NFT',
    [langKey.pages.home.subtitle2]:
      '共4个等级NFT可供购买，价格范围从10 EOS 至10000 EOS。各个等级的徽章代表着4种不同类型的Ariginal投资者。',
    [langKey.pages.home.website]: '网站',
    [langKey.pages.home.contact]: '联系我们',
    [langKey.pages.home.social]: '社交媒体',
    [langKey.pages.home.copyright]: 'ARIGINAL 2021',
  },
  [namespaces.pages.login]: {
    [langKey.pages.login.username]: '用户名',
    [langKey.pages.login.password]: '密码',
    [langKey.pages.login.login]: '登录',
    [langKey.pages.login.register]: '注册',
  },
  [namespaces.pages.register]: {
    [langKey.pages.register.email]: '邮箱',
    [langKey.pages.register.verify]: '验证',
    [langKey.pages.register.verifyCode]: '验证码',
    [langKey.pages.register.countdown]: '秒',
    [langKey.pages.register.password]: '密码',
    [langKey.pages.register.repeatpassword]: '确认密码',
    [langKey.pages.register.register]: '注册',
  },
  [namespaces.pages.productDetail]: {
    [langKey.pages.productDetail.selectBuyTitle]: '选择序列号',
    [langKey.pages.productDetail.buy]: '购买',
    [langKey.pages.productDetail.selectAndBuy]: '购买选择产品',
    [langKey.pages.productDetail.confirmOrder]: '确认购买',
    [langKey.pages.productDetail.serial]: '序列',
    [langKey.pages.productDetail.gotocollection]: '前往收藏',
    [langKey.pages.productDetail.editions]: '版本',
    [langKey.pages.productDetail.level]: '等级',
  },
  [namespaces.pages.profile]: {
    [langKey.pages.profile.collection]: '您的收藏',
    [langKey.pages.profile.withdraw]: '提现',
    [langKey.pages.profile.editions]: '版本',
  },
  [namespaces.pages.withdraw]: {
    [langKey.pages.withdraw.symbol]: '标志',
    [langKey.pages.withdraw.amount]: '数量',
    [langKey.pages.withdraw.chainType]: '区块链类型',
    [langKey.pages.withdraw.from]: '提现地址',
    [langKey.pages.withdraw.memo]: '备忘',
    [langKey.pages.withdraw.receiver]: '收款地址',
    [langKey.pages.withdraw.withdraw]: '提现',
  },
  [namespaces.pages.profileEdit]: {
    [langKey.pages.profileEdit.nickname]: '昵称',
    [langKey.pages.profileEdit.edit]: '编辑',
  },
  [namespaces.common.toast]: {
    [langKey.common.toast.requsterror]: '请求错误，请重试。',
    [langKey.common.toast.pwdnotequal]: '两次输入的密码不一致',
    [langKey.common.toast.emailempty]: '邮箱为空',
    [langKey.common.toast.copysuccess]: '复制成功',
    [langKey.common.toast.noenoughbalance]: '余额不足，请充值。',
    [langKey.common.toast.withdrawsuccess]: '提现成功',
    [langKey.common.toast.selectitem]: '点击列表中的产品以选择',
  },

  [namespaces.common.modal]: {
    [langKey.common.modal.confirm]: '确认',
    [langKey.common.modal.depositinfo]: '充值信息',
    [langKey.common.modal.selectsearial]: '选择序列号',
    [langKey.common.modal.clicktobuy]: '购买选择产品',
    [langKey.common.modal.buy]: '购买',
    [langKey.common.modal.receiver]: '收款地址',
    [langKey.common.modal.copy]: '复制',
    [langKey.common.modal.memo]: '备忘',
    [langKey.common.modal.limit]: '限量',
  },
};
