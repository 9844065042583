import { ExplorerApi } from 'atomicassets';

export const REACT_APP_HOME_COLLECTION = process.env.REACT_APP_HOME_COLLECTION;

const apiAssets = new ExplorerApi(
  process.env.REACT_APP_ATOMIC_API_URL || 'https://dev.ariginal.one:9000',
  'ariginalnfts',
  {}
);

export default apiAssets;
