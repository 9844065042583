import styled from 'styled-components';
import View from 'components/UI/View';
import Modal from 'components/UI/Modal';
import Icon from '@ant-design/icons';
import { isIphonex } from 'lib/utils';

export const ModalContainer = styled(Modal)`
  width: 100vw;
  filter: blur(20px);
  overflow: hidden;
`;

ModalContainer.defaultProps = {
  style: {
    border: 'none',
    borderRadius: 0,
  },
};

export const UnfoldView = styled(View)`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 5.47vh;
  }

  @media (max-width: 767px) {
    height: 4.59vh;
  }
`;

export const MenuUnfold = styled(Icon)`
  opacity: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    img {
      width: 1.25vw;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 4.8vw;
    }
  }
`;

export const HeaderView = styled(View)`
  @media (min-width: 768px) {
    padding-top: 3.9vh;
    padding-left: 2.78vw;
    padding-right: 2.78vw;
  }

  @media (max-width: 767px) {
    padding-top: 2.04vh;
    padding-left: 4.27vw;
    padding-right: 4.27vw;
  }
`;

export const ClosePanel = styled(View)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const LogoImg = styled.img`
  cursor: pointer;
  width: 100%;
  @media (min-width: 768px) {
    width: 5.14vw;
  }

  @media (max-width: 767px) {
    width: 19.73vw;
  }
`;

export const Content = styled(View)`
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: ${() =>
    isIphonex() ? 'calc(100% - 44px - env(safe-area-inset-top))' : '100%'};
`;

export const Cover = styled.div`
  @media (min-width: 768px) {
    margin-top: 3.91vh;
    img {
      width: 31.6vw;
    }
  }

  @media (max-width: 767px) {
    margin-top: 0.51vh;
    img {
      height: 41.02vh;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const Title = styled.div`
  font-weight: 400;
  @media (min-width: 768px) {
    font-size: 3.125vh;
    margin-top: 14.84vh;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    margin-top: 15.29vh;
  }
`;

export const Serial = styled.div`
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 1.95vh;
    margin-top: 2.25vh;
  }

  @media (max-width: 767px) {
    font-size: 1.78vh;
    margin-top: 3.06vh;
  }
`;

export const ContentPrice = styled.div`
  @media (min-width: 768px) {
    font-size: 1.76vh;
    margin-top: 5.66vh;
  }

  @media (max-width: 767px) {
    font-size: 2.29vh;
    margin-top: 1.4vh;
  }
`;

export const Confirm = styled.button`
  background: #ffffff;
  font-weight: 400;
  opacity: 1;
  text-align: center;
  color: #000;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    padding: 1.37vh 1.875vw;
  }

  @media (max-width: 767px) {
    padding: 1.78vh 5.87vw;
    font-size: 1.53vh;
  }
`;

export const ConfirmPanel = styled(View)`
  position: absolute;
  width: 100%;
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 4.1vh;
  }

  @media (max-width: 767px) {
    bottom: 5.1vh;
  }
`;
