import Container from 'components/UI/Container';
import * as S from './styles';
import { useForm } from 'react-hook-form';
import { pickBy } from 'lodash';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import authApi from 'api/auth';
import { useState } from 'react';
import VerifyCountdown from 'components/VerifyCountdown';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import routes from 'routes';

const Register = () => {
  const { register, handleSubmit, getValues, trigger } = useForm({});
  const queryClient = useQueryClient();
  const [verify, setVerify] = useState(false);
  const lang = useLang();

  const navigate = useNavigate();

  const { mutate } = useMutation(authApi.signup, {
    onSuccess: (data, values) => {
      if (data?.code === 200) {
        navigate(routes.login, {
          state: {
            email: values.email,
            token: data.data.token,
          },
          replace: true,
        });
      } else {
        toast.error(data.msg);
      }
    },
    onError: () => {
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    },
    onSettled: () => {
      queryClient.invalidateQueries('register');
    },
  });

  const { mutate: verifyMutate } = useMutation(authApi.verify, {
    onSuccess: (data, values) => {
      if (data?.code === 200 && data?.data && data?.data.result) {
        setVerify(true);
      } else {
        toast.error(data.msg);
      }
    },
    onError: () => {
      setVerify(true);
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    },
    onSettled: () => {
      queryClient.invalidateQueries('register');
    },
  });

  const onSubmit = async (values: any) => {
    if (values.password !== values.repeatpassword) {
      toast.error(lang.toast.t(langKey.common.toast.pwdnotequal));
      return;
    }
    try {
      mutate(
        pickBy({
          ...values,
          cate: 1,
        })
      );
    } catch (e) {
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    }
  };
  const onVerify = async () => {
    const ret = await trigger('email');
    if (!ret) {
      return;
    }
    const values = getValues();
    if (!values.email) {
      toast.error(lang.toast.t(langKey.common.toast.emailempty));
      return;
    }
    try {
      verifyMutate(
        pickBy({
          ...values,
          cate: 1,
        })
      );
    } catch (e) {
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    }
  };

  return (
    <Container>
      <S.Content center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContent center>
            <S.Title>
              {lang.register.t(langKey.pages.register.register)}
            </S.Title>

            <S.Input>
              <S.InputPanel row>
                <S.InputLabel>
                  {lang.register.t(langKey.pages.register.email)}
                </S.InputLabel>
                <S.InputCom
                  type="text"
                  placeholder=""
                  {...register('email', {
                    required: true,
                    pattern:
                      // eslint-disable-next-line no-useless-escape
                      /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
                  })}
                />
                <VerifyCountdown
                  timeout={120}
                  onVerify={onVerify}
                  start={verify}
                  onComplete={() => {
                    setVerify(false);
                  }}
                ></VerifyCountdown>
              </S.InputPanel>
              <S.Border></S.Border>
            </S.Input>
            <S.Input>
              <S.InputPanel row>
                <S.InputLabel>
                  {lang.register.t(langKey.pages.register.verifyCode)}
                </S.InputLabel>
                <S.InputCom
                  type="text"
                  placeholder=""
                  {...register('verify_code', { required: true })}
                />
              </S.InputPanel>
              <S.Border></S.Border>
            </S.Input>
            <S.Input>
              <S.InputPanel row>
                <S.InputLabel>
                  {lang.register.t(langKey.pages.register.password)}
                </S.InputLabel>
                <S.InputCom
                  type="password"
                  placeholder=""
                  {...register('password', { required: true })}
                />
              </S.InputPanel>
              <S.Border></S.Border>
            </S.Input>
            <S.Input>
              <S.InputPanel row>
                <S.InputLabel>
                  {lang.register.t(langKey.pages.register.repeatpassword)}
                </S.InputLabel>
                <S.InputCom
                  type="password"
                  placeholder=""
                  {...register('repeatpassword', { required: true })}
                />
              </S.InputPanel>
              <S.Border></S.Border>
            </S.Input>
            <S.SubmitPanel row center>
              <S.SubmitButton type="submit">
                {lang.register.t(langKey.pages.register.register)}
              </S.SubmitButton>
            </S.SubmitPanel>
          </S.FormContent>
        </form>
      </S.Content>
    </Container>
  );
};

export default Register;
