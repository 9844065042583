import apiAssets from 'api/assets';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import Container from 'components/UI/Container';
import { useEffect, useState } from 'react';
import { useStore } from 'store';
import * as S from './styles';
import { useLocation } from 'react-router';
import ImageList from './ImageList';
import VideoList from './VideoList';
// import ThreeDList from './ThreeDList';
import { toast } from 'react-toastify';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';

const AssetDetail = () => {
  const [detail, setDetail] = useState({} as IAsset);
  const { showLoading, hideLoading, loading } = useStore('loadingStore');
  const { state } = useLocation();
  const lang = useLang();

  useEffect(() => {
    if (state?.asset_id) {
      showLoading();
      apiAssets
        .getAsset(state?.asset_id)
        .then((data) => {
          setDetail(data);
          hideLoading();
        })
        .catch((e) => {
          hideLoading();
          toast.error(lang.toast.t(langKey.common.toast.requsterror));
        });
    }
  }, [hideLoading, state, showLoading, lang.toast]);

  return (
    <Container>
      {loading && (
        <S.Content>
          <S.TopScroller>
            <S.Title>{detail?.name}</S.Title>
            <S.Description>{detail?.data?.desc}</S.Description>
          </S.TopScroller>
          <S.Scroller>
            {detail?.data?.image && (
              <ImageList data={detail?.data?.image} size={1}></ImageList>
            )}
            {detail?.data?.video && (
              <VideoList data={detail?.data?.video} size={1}></VideoList>
            )}
            {/* {detail?.data?.model && (
              <ThreeDList data={detail?.data?.model} size={1}></ThreeDList>
            )} */}
          </S.Scroller>
        </S.Content>
      )}
    </Container>
  );
};

export default AssetDetail;
