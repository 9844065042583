import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import React, { useEffect, useState } from 'react';
import * as S from './styles';

const VerifyCountdown = ({
  start = false,
  timeout = 120,
  onVerify,
  onComplete,
}: {
  start: boolean;
  timeout: number;
  onVerify: () => void;
  onComplete: () => void;
}) => {
  // 当前秒数
  const [second, setSecond] = useState(timeout);
  const lang = useLang();
  useEffect(() => {
    let timer: NodeJS.Timeout;

    function countdown() {
      setSecond((preSecond: number) => {
        if (preSecond <= 1) {
          onComplete();
          // 重置秒数
          clearTimeout(timer);
          return timeout;
        } else {
          clearTimeout(timer);
          timer = setTimeout(countdown, 1000);
          return preSecond - 1;
        }
      });
    }
    // 开始倒计时
    if (start) {
      timer = setTimeout(countdown, 1000);
    }
    return () => clearTimeout(timer);
  }, [start, onComplete, timeout]);
  return (
    <S.VerifyButton
      type={'button'}
      start={start ? 1 : 0}
      onClick={() => {
        if (!start) {
          onVerify();
        }
      }}
    >
      {start
        ? `(${second}${lang.register.t(langKey.pages.register.countdown)})`
        : lang.register.t(langKey.pages.register.verify)}
    </S.VerifyButton>
  );
};

export default VerifyCountdown;
