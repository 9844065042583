import React, { useCallback, useMemo } from 'react';
import * as S from './styles';
import { useStore } from 'store';
import { useDepositInfo } from 'api/exchange';
// import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { asset, symbol } from 'eos-common';
import { toast } from 'react-toastify';
import ariginal from '../asset/ARIGINAL.png';
import close from '../asset/Close.png';
import { useNavigate } from 'react-router';
import routes from 'routes';
import { replaceSymbol } from 'lib/utils';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';

const ShowDeposit = () => {
  const { closeModal } = useStore('modalsStore');
  const lang = useLang();

  const { data } = useDepositInfo();
  const navigate = useNavigate();
  const qrCode = useMemo(() => {
    if (!data || !data.data || !data.data.qrCode) return null;
    return JSON.parse(data.data.qrCode);
  }, [data]);

  const onClose = useCallback(() => {
    closeModal('showDeposit');
  }, [closeModal]);

  return (
    <S.ModalContainer width={'100vw'}>
      <S.HeaderView row centerV>
        <S.LogoImg
          src={ariginal}
          alt={''}
          onClick={() => {
            navigate(routes.home);
          }}
        ></S.LogoImg>
        <S.ClosePanel right>
          <S.UnfoldView centerV right>
            <S.MenuUnfold
              component={() => {
                return <img src={close} alt={''}></img>;
              }}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            ></S.MenuUnfold>
          </S.UnfoldView>
        </S.ClosePanel>
      </S.HeaderView>
      <S.Content>
        <S.Title>{lang.modal.t(langKey.common.modal.depositinfo)}</S.Title>
        {/* <S.Cover>
          {data && data.data && data.data.qrCode && (
            <QRCode value={data.data.qrCode} size={208}></QRCode>
          )}
        </S.Cover> */}
        <S.Description>
          <S.Border></S.Border>
          <S.CopyPanel row centerV>
            <S.ContentText>
              {lang.modal.t(langKey.common.modal.receiver)}
            </S.ContentText>
            <S.ContentPrice>{data?.data.receiver}</S.ContentPrice>
            <CopyToClipboard
              text={data?.data.receiver}
              onCopy={() =>
                toast.success(lang.toast.t(langKey.common.toast.copysuccess))
              }
            >
              <S.Copy>{lang.modal.t(langKey.common.modal.copy)}</S.Copy>
            </CopyToClipboard>
          </S.CopyPanel>
          <S.Border></S.Border>
          <S.CopyPanel row centerV>
            <S.ContentText>
              {lang.modal.t(langKey.common.modal.memo)}
            </S.ContentText>
            <S.ContentPrice>{qrCode?.memo}</S.ContentPrice>
            <CopyToClipboard
              text={qrCode?.memo}
              onCopy={() =>
                toast.success(lang.toast.t(langKey.common.toast.copysuccess))
              }
            >
              <S.Copy>{lang.modal.t(langKey.common.modal.copy)}</S.Copy>
            </CopyToClipboard>
          </S.CopyPanel>
          {data?.data && <S.Border></S.Border>}

          {data?.data && data?.data?.dailyLimitAmount && (
            <>
              <S.CopyPanel row centerV>
                <S.ContentText>
                  {lang.modal.t(langKey.common.modal.limit)}
                </S.ContentText>
                <S.ContentPrice>
                  {replaceSymbol(
                    asset(
                      BigInt(data?.data?.dailyLimitAmount),
                      symbol(qrCode?.symbol, 4)
                    ).toString()
                  )}
                </S.ContentPrice>
                <S.Copy></S.Copy>
              </S.CopyPanel>
              <S.Border></S.Border>
            </>
          )}
        </S.Description>
      </S.Content>
    </S.ModalContainer>
  );
};

export default ShowDeposit;
