import api from './index';

const routes = {
  userPath() {
    return 'user';
  },
  signupPath() {
    return [this.userPath(), 'register'].join('/');
  },
  loginPath() {
    return [this.userPath(), 'login'].join('/');
  },
  verifyPath() {
    return [this.userPath(), 'send_email'].join('/');
  },
  saveInfoPath() {
    return [this.userPath(), 'save_info'].join('/');
  },
};

const authApi = {
  async signup(
    body: Partial<{
      email: string;
      verify_code: string;
      password: string;
    }>
  ) {
    const { data } = await api.post(routes.signupPath(), body);
    return data;
  },
  async verify(
    body: Partial<{
      email: string;
    }>
  ) {
    const { data } = await api.post(routes.verifyPath(), body);
    return data;
  },
  async auth(
    body: Partial<{
      username: string;
      password: string;
    }>
  ) {
    const { data } = await api.post(routes.loginPath(), {
      ...body,
      cate: 1,
    });
    return data;
  },
  async saveInfo(
    body: Partial<{
      nickName: string;
      avatar: string;
    }>
  ) {
    const { data } = await api.post(routes.saveInfoPath(), {
      ...body,
      avatar: '',
    });
    return data;
  },
};

export default authApi;
