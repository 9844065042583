import styled from 'styled-components';
import View from 'components/UI/View';
import InfiniteScroll from 'react-infinite-scroller';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import Modal from 'components/UI/Modal';
import { isIphonex } from 'lib/utils';

export const ModalContainer = styled(Modal)`
  width: 100vw;
  filter: blur(20px);
  overflow: hidden;
`;

ModalContainer.defaultProps = {
  style: {
    border: 'none',
    borderRadius: 0,
  },
};

export const UnfoldView = styled(View)`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 5.47vh;
  }

  @media (max-width: 767px) {
    height: 4.59vh;
  }
`;

export const MenuUnfold = styled(Icon)`
  opacity: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    img {
      width: 1.25vw;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 4.8vw;
    }
  }
`;

export const HeaderView = styled(View)`
  @media (min-width: 768px) {
    padding-top: 3.9vh;
    padding-left: 2.78vw;
    padding-right: 2.78vw;
  }

  @media (max-width: 767px) {
    padding-top: 2.04vh;
    padding-left: 4.27vw;
    padding-right: 4.27vw;
  }
`;

export const ClosePanel = styled(View)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const LogoImg = styled.img`
  cursor: pointer;
  width: 100%;
  @media (min-width: 768px) {
    width: 5.14vw;
  }

  @media (max-width: 767px) {
    width: 19.73vw;
  }
`;

export const Content = styled(View)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: ${() =>
    isIphonex() ? 'calc(100% - 44px - env(safe-area-inset-top))' : '100%'};
`;

export const LabelPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    margin-top: 7.32vh;

    img {
      height: 24.41vh;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5.73vh;
    height: 31.85vh;
    img {
      width: 100%;
    }
  }
`;

export const ContentLabel = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9;
  text-align: center;
`;

export const Title = styled.div`
  font-weight: 400;
  @media (min-width: 768px) {
    font-size: 3.125vh;
    margin-top: 7.32vh;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    margin-top: 9.55vh;
  }
`;

export const SelectLabel = styled.div`
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    margin-top: 3.9vh;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    margin-top: 5.1vh;
  }
`;

export const ClickLabel = styled.div`
  font-weight: 600;
  opacity: 0.5;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    margin-top: 0.88vh;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    margin-top: 1.15vh;
  }
`;

export const ListPanel = styled.div<{ hasMore?: boolean }>`
  overflow: auto;

  -webkit-mask-image: linear-gradient(
    #ffffff calc(100% - 19.11vh),
    transparent
  );
  @media (min-width: 768px) {
    margin-top: 0.49vh;
    text-align: center;
    height: 55.57vh;
    width: 26.04vw;

    /* 滚动槽 */
    ::-webkit-scrollbar {
      width: 0.16vw;
      height: 0.16vh;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0.08vw;
      background: rgba(255, 255, 255, 0.04);
      -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.08);
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 0.08vw;
      background: rgba(255, 255, 255, 0.08);
      -webkit-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2);
    }
    padding-bottom: ${({ hasMore }) => (hasMore ? '0' : '11.72vh')};
  }

  @media (max-width: 767px) {
    margin-top: 0.64vh;
    height: 54.39vh;

    /* 滚动槽 */
    ::-webkit-scrollbar {
      width: 0.5vw;
      height: 0.5vh;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0.26vw;
      background: rgba(255, 255, 255, 0.04);
      -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.08);
    }

    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 0.26vw;
      background: rgba(255, 255, 255, 0.08);
      -webkit-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2);
    }
    padding-bottom: ${({ hasMore }) => (hasMore ? '0' : '12.74vh')};
  }
`;
export const Scroller = styled(InfiniteScroll)``;

export const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 100vw));
  justify-content: center;
`;

export const Border = styled.div`
  background-color: #fff;
  height: 1px;
`;

export const RowPanel = styled.li<{ isActive?: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#fff' : '#000')};
  color: ${({ isActive }) => (isActive ? '#000' : '#fff')};
`;

export const Row = styled(View)`
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 1.25vw;
    padding: 1.95vh 1.11vw 1.95vh 1.67vw;
  }

  @media (max-width: 767px) {
    font-size: 2.29vh;
    padding: 2.55vh 6.4vw 2.55vh 4.27vw;
  }
`;

export const Serial = styled.div`
  text-align: center;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const Price = styled.div`
  flex: 1;
  text-align: right;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const Confirm = styled.button`
  background: #ffffff;
  font-weight: 400;
  opacity: 1;
  text-align: center;
  z-index: 10;
  color: #000;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    padding: 1.37vh 1.875vw;
  }

  @media (max-width: 767px) {
    padding: 1.78vh 7.2vw;
    font-size: 1.53vh;
  }
`;

export const ConfirmPanel = styled(View)`
  text-align: center;
  position: absolute;
  z-index: 1001;
  width: 100%;
  @media (min-width: 768px) {
    bottom: 0;
    padding-bottom: 6.25vh;
  }

  @media (max-width: 767px) {
    bottom: 0;
    padding-bottom: 5.1vh;
  }
`;

export const LoadingIcon = styled(LoadingOutlined)`
  @media (min-width: 768px) {
    font-size: 2.22vw;
  }

  @media (max-width: 767px) {
  }
`;

export const Loading = styled(View)`
  width: 100%;
  text-align: center;
`;
