import { makeAutoObservable } from 'mobx';

class LoadingStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  showLoading = () => {
    this.loading = true;
  };

  hideLoading = () => {
    this.loading = false;
  };
}

export default LoadingStore;
