import React, { useRef, useCallback, useState } from 'react';
import * as S from './styles';
import { useStore } from 'store';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import {
  Price,
  Asset,
} from '@bigblockchain/atomicmarket/build/API/Explorer/Types';
import { asset, symbol } from 'eos-common';
import { useMutation, useQueryClient } from 'react-query';
import exchangeApi from 'api/exchange';
import { toast } from 'react-toastify';
import { isEmpty, pickBy } from 'lodash';
import { useNavigate } from 'react-router';
import routes from 'routes';
import ariginal from '../asset/ARIGINAL.png';
import walletApi from 'api/wallet';
import close from '../asset/Close.png';
import { replaceSymbol } from 'lib/utils';

const ShowDetail = () => {
  const { openModal, closeModal, modals } = useStore('modalsStore');
  const price = useRef<Price>(modals.get('showDetail')?.price as Price);
  const assetDetail = useRef<Asset>(modals.get('showDetail')?.asset as Asset);
  const saleId = useRef<number>(modals.get('showDetail')?.saleId as number);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const lang = useLang();
  const queryClient = useQueryClient();

  const onClose = useCallback(() => {
    closeModal('showDetail');
  }, [closeModal]);

  const { mutate } = useMutation(exchangeApi.purchaseSale, {
    onSuccess: (data, values) => {
      if (data?.code === 200 && data?.data?.isSuccess) {
        setIsSuccess(true);
      } else {
        toast.error(data.msg);
      }
    },
    onError: () => {
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    },
    onSettled: () => {
      queryClient.invalidateQueries('login');
    },
  });

  const { mutate: mutateBalance } = useMutation(walletApi.getBalance, {
    onSuccess: (data) => {
      if (data?.code === 200) {
        if (data.data.balance) {
          const balanceAsset = asset(data.data.balance);
          const amount = BigInt(price.current.amount);
          if (balanceAsset.amount.compareTo(amount) < 0) {
            toast.info(lang.toast.t(langKey.common.toast.noenoughbalance));
            onClose();
            openModal('showDeposit');
            return;
          }
        } else if (isEmpty(data.data.balance)) {
          toast.info(lang.toast.t(langKey.common.toast.noenoughbalance));
          onClose();
          openModal('showDeposit');
          return;
        }
      }
      onPurchase();
    },
    onError: () => {
      // toast.error(langError(langKey.common.error.requsterror));
      onPurchase();
    },
    onSettled: () => {
      queryClient.invalidateQueries('login');
    },
  });

  const onPurchase = useCallback(() => {
    try {
      mutate(
        pickBy({
          saleId: parseInt(saleId.current.toString()),
          buyer: '',
          buyQuantity: {
            quantity: asset(
              BigInt(price.current.amount),
              symbol(price.current.token_symbol, price.current.token_precision)
            ).toString(),
            contract: price.current.token_contract,
          },
          purchaseMemo:
            'buy NFT:' +
            assetDetail.current.name +
            ' #Serial #' +
            assetDetail.current.template_mint,
        })
      );
    } catch (e) {
      toast.error(lang.toast.t(langKey.common.toast.requsterror));
    }
  }, [lang, mutate]);

  const handlePurchaseSale = useCallback(async () => {
    // console.log(data.data.balance);
    if (isSuccess) {
      onClose();
      navigate(routes.profile);
      return;
    }
    try {
      mutateBalance({
        symbol: price.current.token_symbol,
        contract: price.current.token_contract,
      });
    } catch (error) {
      onPurchase();
    }
  }, [isSuccess, mutateBalance, navigate, onClose, onPurchase]);
  return (
    <S.ModalContainer width={'100vw'}>
      <S.HeaderView row centerV>
        <S.LogoImg
          src={ariginal}
          alt={''}
          onClick={() => {
            navigate(routes.home);
          }}
        ></S.LogoImg>
        <S.ClosePanel right>
          <S.UnfoldView centerV right>
            <S.MenuUnfold
              component={() => {
                return <img src={close} alt={''}></img>;
              }}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            ></S.MenuUnfold>
          </S.UnfoldView>
        </S.ClosePanel>
      </S.HeaderView>
      <S.Content>
        <S.Title>{assetDetail?.current.name}</S.Title>
        <S.Serial>{'#' + assetDetail.current.template_mint}</S.Serial>
        <S.Cover>
          <img src={assetDetail?.current?.immutable_data.cover} alt={''}></img>
        </S.Cover>
        <S.ContentPrice>
          {replaceSymbol(
            asset(
              BigInt(price.current.amount),
              symbol(price.current.token_symbol, price.current.token_precision)
            ).toString()
          )}
        </S.ContentPrice>
        <S.ConfirmPanel center>
          <S.Confirm onClick={handlePurchaseSale}>
            {isSuccess
              ? lang.productDetail.t(langKey.pages.productDetail.gotocollection)
              : lang.productDetail.t(langKey.pages.productDetail.confirmOrder)}
          </S.Confirm>
        </S.ConfirmPanel>
      </S.Content>
    </S.ModalContainer>
  );
};

export default ShowDetail;
