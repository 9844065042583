import { useTranslation } from 'react-i18next';

export const namespaces = {
  pages: {
    login: 'pages.login',
    register: 'pages.register',
    home: 'pages.home',
    market: 'pages.market',
    productDetail: 'pages.productDetail',
    profile: 'pages.profile',
    profileEdit: 'pages.profileEdit',
    withdraw: 'pages.withdraw',
  },
  common: {
    menu: 'common.menu',
    toast: 'common.error',
    modal: 'common.modal',
  },
};

export const languages = {
  en: 'en',
  zhCN: 'zh-CN',
};

export const useLang = () => {
  return {
    login: useTranslation(namespaces.pages.login),
    register: useTranslation(namespaces.pages.register),
    home: useTranslation(namespaces.pages.home),
    productDetail: useTranslation(namespaces.pages.productDetail),
    profile: useTranslation(namespaces.pages.profile),
    withdraw: useTranslation(namespaces.pages.withdraw),
    editProfile: useTranslation(namespaces.pages.profileEdit),
    menu: useTranslation(namespaces.common.menu),
    toast: useTranslation(namespaces.common.toast),
    modal: useTranslation(namespaces.common.modal),
  };
};
