import i18next, { i18n as i18nInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { languages } from './i18n.constants';
import Language from './i18n.translations';

const createI18n = (): i18nInstance => {
  const i18n = i18next
    .createInstance()
    .use(LanguageDetector)
    .use(initReactI18next);

  i18n.init({
    lng: navigator.language,
    fallbackLng: languages.en,
    resources: {
      [languages.en]: Language.en,
      [languages.zhCN]: Language.zhCN,
    },
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

  return i18n;
};

export const i18n = createI18n();
