import styled from 'styled-components';

export const Video = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #000;
  justify-content: center;

  video: {
    @media (min-width: 768px) {
      object-fit: fill;
    }

    @media (max-width: 767px) {
      width: 100%;
      min-height: 39.49vh;
      object-fit: cover;
    }
  }
`;

export const PlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  user-select: none;
  pointer-events: none;
`;

export const VideoMask = styled.div`
  width: 100%;
  min-height: 100%;
`;

export const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 99;
  color: #ffffff;
`;

export const PlayButton = styled.img`
  position: relative;
  pointer-events: initial;
  height: auto;
  @media (min-width: 768px) {
    width: 2.78vw;
  }

  @media (max-width: 767px) {
    width: 5.1vh;
  }
`;
