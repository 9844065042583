import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import React from 'react';
import * as S from './styles';

const VideoList = ({ data, size }: { data: string[]; size: number }) => {
  const renderItem = (data: string, index: number) => {
    return (
      <li key={index}>
        <VideoPlayer url={data} />
      </li>
    );
  };

  return <S.Grid panel={size}>{data.map(renderItem)}</S.Grid>;
};

export default VideoList;
