import axios, { AxiosError, AxiosTransformer } from 'axios';
import qs from 'query-string';
import { decamelizeKeys, camelizeKeys } from 'humps';

const baseURL = process.env.REACT_APP_BASE_URL;
let token = localStorage.getItem('token');

const defaultTransformers = (
  transformRequest?: AxiosTransformer | AxiosTransformer[]
): AxiosTransformer[] => {
  if (!transformRequest) {
    return [];
  }
  if (transformRequest instanceof Array) {
    return transformRequest;
  }

  return [transformRequest];
};

const api = axios.create({
  baseURL,
  headers: {
    ...(token && { Authorization: `${token}` }),
  },
  transformRequest: [
    (data: any): unknown => {
      if (data instanceof FormData) {
        return data;
      }
      return data; //decamelizeKeys(data, { split: /(?=[A-Z0-9])/ });
    },
    ...defaultTransformers(axios.defaults.transformRequest),
  ],
  transformResponse: [
    ...defaultTransformers(axios.defaults.transformResponse),
    (data: any): unknown => {
      return camelizeKeys(data);
    },
  ],
  paramsSerializer: (params) =>
    qs.stringify(decamelizeKeys(params), {
      skipEmptyString: true,
      skipNull: true,
    }),
});

api.interceptors.response.use(
  function (response) {
    if (response.data.code && response.data.code === 300) {
      localStorage.setItem('token', '');
      localStorage.setItem('account', JSON.stringify(null));
      window.location.href = '/login';
    }
    return response;
  },
  function (error: AxiosError) {
    if (!error.response) {
      return Promise.reject(error);
    }
    const {
      response: { status },
    } = error;

    if (status === 401 || status === 403) {
      localStorage.setItem('token', '');
      localStorage.setItem('account', JSON.stringify(null));
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export function refreshToken(tokenNew: string) {
  // console.log('newTken', token, tokenNew);
  token = tokenNew;
  setTokenHeader(tokenNew);
}

export function setTokenHeader(token: string) {
  const storedToken = localStorage.getItem('token');

  if (!token && !storedToken) {
    return;
  }

  api.defaults.headers.common.Authorization = `${token || storedToken}`;
  api.defaults.headers.Authorization = `${token || storedToken}`;
}

export default api;
