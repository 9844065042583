import { LoadingOutlined } from '@ant-design/icons';
import View from 'components/UI/View';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

export const Content = styled(View)`
  width: 100vw;
  height: 100%;
  opacity: 1;

  @media (min-width: 768px) {
    text-align: center;
    font-size: 1.46vh;
    padding-top: 12.5vh;
  }

  @media (max-width: 767px) {
    text-align: center;
    font-size: 1.91vh;
    padding-top: 14.01vh;
  }
`;

export const Name = styled.div``;

export const TokenPanel = styled(View)`
  display: flex;
  @media (min-width: 768px) {
    margin-top: 2.44vh;
    padding-left: 38.33vw;
    padding-right: 38.33vw;
    margin-bottom: 2.24vh;
  }

  @media (max-width: 767px) {
    padding-left: 21.87vw;
    padding-right: 21.87vw;
    margin-bottom: 2.29vh;
  }
`;

export const Title = styled.div`
  flex: 1;
  text-align: left;
`;

export const Value = styled.div`
  flex: 1;
  text-align: right;
`;

export const WithdrwaButton = styled.button`
  opacity: 0.5;
  text-align: center;
  flex: 1;
  color: #fff;
  @media (min-width: 768px) {
    font-size: 1.46vh;
    margin-top: 2.44vh;
  }

  @media (max-width: 767px) {
    margin-top: 7.01vh;
    font-size: 1.91vh;
  }
`;

export const CollectTitle = styled.div`
  font-weight: 600;
  white-space: pre-line;
  @media (min-width: 768px) {
    font-size: 1.17vh;
    margin-top: 13.96vh;
    margin-bottom: 2.34vh;
  }

  @media (max-width: 767px) {
    margin-top: 11.59vh;
    font-size: 1.53vh;
  }
`;
export const Scroller = styled(InfiniteScroll)``;

export const RowItem = styled.li`
  @media (min-width: 768px) {
    position: relative;
    min-height: 74.02vh;
    border-bottom: 1px solid #fff;
    &:nth-child(odd) {
      border-top: 1px solid #fff;
    }
  }

  @media (max-width: 767px) {
    border-bottom: 1px solid #fff;
    &:nth-child(odd) {
      border-top: 1px solid #fff;
    }
  }
`;

export const FirstPanel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 767px) {
  }
`;

export const Cover = styled.div`
  flex: 1;
  @media (min-width: 768px) {
    position: relative;
    min-height: 74.71vh;
  }

  @media (max-width: 767px) {
  }
`;

export const ImagePanel = styled.div`
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    height: 43.57vh;
  }
`;
export const InfoImage = styled.img`
  @media (min-width: 768px) {
    width: 100%;
    min-height: 74.71vh;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(
      #ffffff calc(100% - 25.22vh),
      transparent
    );
  }

  @media (max-width: 767px) {
    width: 100%;
    min-height: 43.57vh;
    object-fit: cover;
  }
`;

export const DetailPanel = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    bottom: 3.91vh;
    min-height: 18.34vh;
    padding-left: 2.64vw;
    padding-right: 2.78vw;
    z-index: 99;
    filter: blur(0px);
  }

  @media (max-width: 767px) {
    padding-left: 4.27vw;
    padding-right: 4.27vw;
    margin-top: 1.15vh;
  }
`;
export const DetailLabel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    font-size: 1.17vh;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    font-size: 1.53vh;
  }
`;

export const InfoLabel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
  }
`;
export const LevelLabel = styled.div`
  opacity: 0.5;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;
export const EditionLabel = styled.div`
  @media (min-width: 768px) {
    margin-left: 1.11vw;
  }

  @media (max-width: 767px) {
    margin-left: 2.67vw;
  }
`;

export const PriceLabel = styled.div`
  @media (min-width: 768px) {
    margin-left: 1.11vw;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    flex: 1;
    text-align: right;
  }
`;

export const DescriptionPanel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 2.34vh;
  }

  @media (max-width: 767px) {
    margin-top: 2.04vh;
    display: flex;
    flex-direction: row;
  }
`;

export const InfoPanel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex: 1;
    text-align: left;
  }

  @media (max-width: 767px) {
    flex: 1;
    text-align: left;
  }
`;

export const TitleLabel = styled.div`
  @media (min-width: 768px) {
    font-size: 3.125vh;
    max-width: 12.5vw;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
    text-align: left;
  }
`;

export const DescLabel = styled.div`
  white-space: pre-line;
  flex: 1;
  color: rgba(255, 255, 255, 0.75);
  ul {
    list-style: disc;
    list-style-position: inside;
    font-size: 0;
    li {
      @media (min-width: 768px) {
        line-height: 1.56vh;
        font-size: 1.17vh;
        text-indent: -17px;
        padding-left: 24px;
      }

      @media (max-width: 767px) {
        line-height: 2.04vh;
        font-size: 1.53vh;
        text-indent: -17px;
        padding-left: 24px;
      }
    }
  }
  @media (min-width: 768px) {
    padding-right: 3.82vw;
    padding-left: 6.46vw;
  }

  @media (max-width: 767px) {
    margin-top: 3.06vh;
    font-weight: 400;
    font-size: 1.53vh;
    margin-bottom: 1.02vh;
  }
`;

export const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 100vw}));

  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    margin-top: 1.91vh;
  }
`;

export const SecondPanel = styled.div`
  flex: 1;
  position: relative;
`;

export const DetailImagePanel = styled.div`
  flex: 1;
  position: relative;
`;

export const Image = styled.img`
  @media (min-width: 768px) {
    width: 100%;
    height: 74.71vh;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 62.17vh;
    object-fit: cover;
  }
`;

export const ItemTitle = styled.div`
  text-align: left;
  @media (min-width: 768px) {
    position: absolute;
    top: 55.37vh;
    font-size: 3.125vh;
    left: 2.64vw;
    max-width: 22.44vw;
  }

  @media (max-width: 767px) {
    position: absolute;
    bottom: 3.44vh;
    font-size: 4.08vh;
    left: 4.27vw;
  }
`;

export const VideoPanel = styled.div`
  @media (min-width: 768px) {
    position: relative;
    height: 74.71vh;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 62.17vh;
    object-fit: cover;
  }
`;

export const LoadingIcon = styled(LoadingOutlined)`
  color: #fff;
  @media (min-width: 768px) {
    font-size: 2.22vw;
  }

  @media (max-width: 767px) {
  }
`;

export const Loading = styled(View)`
  width: 100%;
  text-align: center;
`;

export const FooterPannel = styled(View)`
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 11.23vh;
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
  }
`;

export const FooterTitle = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 14.65vh;
  }

  @media (max-width: 767px) {
    font-size: 8.15vh;
  }
`;

export const Logo = styled.div`
  @media (min-width: 768px) {
    margin-top: 5.86vh;
    img {
      width: 3.89vw;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
    img {
      height: 7.13vh;
    }
  }
`;

export const Copyright = styled.div`
  @media (min-width: 768px) {
    margin-top: 4vh;
    padding-bottom: 8.2vh;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 2.04vh;
    margin-top: 5.1vh;
    padding-bottom: 8.66vh;
  }
`;
