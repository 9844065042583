import GlobalHeader from 'components/Header';
import { ReactNode } from 'react';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';

const ContainerWrap = styled.div<{ innerHeight: number }>`
  background-size: 100% 100%;
  background-color: #000000;
  height: 100%;
  height: ${({ innerHeight }) => innerHeight + 'px'};
  @media (min-width: 768px) {
    overflow: auto;

    /* 滚动槽 */
    ::-webkit-scrollbar {
      width: 0;
      height: 0.16vw;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0.08vw;
      background: rgba(255, 255, 255, 0.04);
      -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.08);
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 0.08vw;
      background: rgba(255, 255, 255, 0.08);
      -webkit-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2);
    }
  }

  @media (max-width: 767px) {
    overflow: auto;

    /* 滚动槽 */
    ::-webkit-scrollbar {
      width: 0;
      height: 0.5vw;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0.26vw;
      background: rgba(255, 255, 255, 0.04);
      -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.08);
    }

    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 0.26vw;
      background: rgba(255, 255, 255, 0.08);
      -webkit-box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2);
    }
  }

  display: flex;
  flex-direction: column;
`;

const Container = ({ children }: { children: ReactNode }) => {
  const { height: windowInnerHeight } = useWindowSize();

  return (
    <ContainerWrap innerHeight={windowInnerHeight}>
      <GlobalHeader />
      {children}
    </ContainerWrap>
  );
};

export default Container;
