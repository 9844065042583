import apiAssets from 'api/assets';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import Container from 'components/UI/Container';
import { useEffect, useState } from 'react';
import { useStore } from 'store';
import * as S from './styles';
import { useNavigate, useParams } from 'react-router';
import List from './List';
import { toast } from 'react-toastify';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import logo from '../assets/Logo.png';
import ReactMarkdown from 'react-markdown';
import arrow from '../assets/arrow.svg';
import {
  getAUARTitle,
  getEditions,
  getLevel,
  getMasTitle,
  getPrice,
} from 'lib/utils';
import { DetailInfo } from 'types/ino';

const ProductDetail = () => {
  const [detail, setDetail] = useState({} as IAsset);
  const [detailInfos, setDetailInfos] = useState([] as DetailInfo[]);
  const { showLoading, hideLoading, loading } = useStore('loadingStore');
  const param = useParams();
  const { openModal } = useStore('modalsStore');
  const lang = useLang();
  const navigate = useNavigate();

  useEffect(() => {
    if (param.template) {
      showLoading();
      apiAssets
        .getAssets({ template_id: parseInt(param.template) }, 1, 1)
        .then((data) => {
          setDetail(data[0]);
          let tmplist: DetailInfo[] = [];
          if (data[0]?.data?.image) {
            for (let i: number = 0; i < data[0].data.image.length; i++) {
              tmplist.push({
                type: 'image',
                url: data[0].data?.image[i],
                title: getMasTitle(data[0]?.name),
              });
            }
          }
          if (data[0]?.data?.video) {
            for (let i: number = 0; i < data[0].data.image.length; i++) {
              tmplist.push({
                type: 'video',
                url: data[0].data?.video[i],
                title: getAUARTitle(data[0]?.name),
              });
            }
          }
          setDetailInfos(tmplist);
          hideLoading();
        })
        .catch((e) => {
          hideLoading();
          toast.error(lang.toast.t(langKey.common.toast.requsterror));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideLoading, showLoading]);

  const handleSelectAndBuy = () => {
    openModal('selectBuy', {
      templateId: param.template,
      collectionName: param.collection,
      name: detail.immutable_data.name,
      cover: detail.data.cover,
    });
  };

  return (
    <Container>
      {loading && (
        <S.Content>
          <S.TopPanel>
            <S.FirstPanel>
              <S.InfoLabel>
                <S.LevelLabel>
                  {lang.productDetail.t(langKey.pages.productDetail.level) +
                    ' ' +
                    getLevel(detail?.name)}
                </S.LevelLabel>
                <S.EditionLabel>
                  {getEditions(
                    detail?.name,
                    parseInt(
                      detail?.template?.max_supply
                        ? detail?.template?.max_supply
                        : '0'
                    )
                  ) +
                    ' ' +
                    lang.productDetail.t(langKey.pages.productDetail.editions)}
                </S.EditionLabel>
                <S.PriceLabel>{getPrice(detail?.name)}</S.PriceLabel>
              </S.InfoLabel>
              <S.Title>{detail?.name}</S.Title>
              <div>
                <S.SubmitButton type="button" onClick={handleSelectAndBuy}>
                  {lang.productDetail.t(
                    langKey.pages.productDetail.selectAndBuy
                  )}
                </S.SubmitButton>
              </div>
              <S.GoButton>
                <button
                  type={'button'}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <S.GoImg src={arrow} alt={''}></S.GoImg>
                </button>
              </S.GoButton>
            </S.FirstPanel>
            <S.CoverPanel>
              <img src={detail?.data?.cover} alt={''}></img>
            </S.CoverPanel>
            <S.SecondPanel>
              <S.Description>
                <ReactMarkdown children={detail?.data?.desc}></ReactMarkdown>
              </S.Description>
            </S.SecondPanel>
          </S.TopPanel>
          {detailInfos && detailInfos.length > 0 && (
            <List data={detailInfos}></List>
          )}
          <S.FooterPannel>
            <S.FooterLabel
              onClick={() =>
                (window.location.href = 'mailto:hello@ariginal.one')
              }
            >
              <span>{lang.home.t(langKey.pages.home.contact)}</span>
            </S.FooterLabel>
            <S.FooterLabel
              onClick={() =>
                window.open('https://www.instagram.com/ariginal.one/')
              }
            >
              <span>{lang.home.t(langKey.pages.home.social)}</span>
            </S.FooterLabel>
            <S.Logo>
              <img src={logo} alt={''}></img>
            </S.Logo>
            <S.Copyright>
              <span>{lang.home.t(langKey.pages.home.copyright)}</span>
            </S.Copyright>
          </S.FooterPannel>
        </S.Content>
      )}
      {loading && (
        <S.ContentMobile>
          <S.GoButton>
            <button
              type={'button'}
              onClick={() => {
                navigate(-1);
              }}
            >
              <S.GoImg src={arrow} alt={''}></S.GoImg>
            </button>
          </S.GoButton>
          <S.FirstPanel>
            <S.CoverPanel>
              <img src={detail?.data?.cover} alt={''}></img>
            </S.CoverPanel>
            <S.InfoPanel>
              <S.InfoLabel>
                <S.LevelLabel>
                  {lang.productDetail.t(langKey.pages.productDetail.level) +
                    ' ' +
                    getLevel(detail?.name)}
                </S.LevelLabel>
                <S.EditionLabel>
                  {getEditions(
                    detail?.name,
                    parseInt(
                      detail?.template?.max_supply
                        ? detail?.template?.max_supply
                        : '0'
                    )
                  ) +
                    ' ' +
                    lang.productDetail.t(langKey.pages.productDetail.editions)}
                </S.EditionLabel>
                <S.PriceLabel>{getPrice(detail?.name)}</S.PriceLabel>
              </S.InfoLabel>
              <S.Title>{detail?.name}</S.Title>
              <div>
                <S.SubmitButton type="button" onClick={handleSelectAndBuy}>
                  {lang.productDetail.t(
                    langKey.pages.productDetail.selectAndBuy
                  )}
                </S.SubmitButton>
              </div>
            </S.InfoPanel>
          </S.FirstPanel>
          <S.Border></S.Border>
          <S.Description>
            <ReactMarkdown children={detail?.data?.desc}></ReactMarkdown>
          </S.Description>
          <S.SecondPanel>
            {detailInfos && detailInfos.length > 0 && (
              <List data={detailInfos}></List>
            )}
          </S.SecondPanel>
          <S.FooterPannel>
            <S.FooterLabel
              onClick={() =>
                (window.location.href = 'mailto:hello@ariginal.one')
              }
            >
              <span>{lang.home.t(langKey.pages.home.contact)}</span>
            </S.FooterLabel>
            <S.FooterLabel
              onClick={() =>
                window.open('https://www.instagram.com/ariginal.one/')
              }
            >
              <span>{lang.home.t(langKey.pages.home.social)}</span>
            </S.FooterLabel>
            <S.Logo>
              <img src={logo} alt={''}></img>
            </S.Logo>
            <S.Copyright>
              <span>{lang.home.t(langKey.pages.home.copyright)}</span>
            </S.Copyright>
          </S.FooterPannel>
        </S.ContentMobile>
      )}
    </Container>
  );
};

export default ProductDetail;
