import { useQuery } from 'react-query';
import { BalanceInfo } from 'types/wallet';
import api from './index';

export const REACT_SYMBOL_CONTRACT = process.env.REACT_SYMBOL_CONTRACT
  ? process.env.REACT_SYMBOL_CONTRACT
  : 'argtokenmint';

const routes = {
  walletPath() {
    return '/wallet';
  },
  balancePath() {
    return [this.walletPath(), 'get_balance'].join('/');
  },
};

const walletApi = {
  async getBalance(body: Partial<{ symbol: string; contract: string }>) {
    const { data } = await api.post<BalanceInfo>(routes.balancePath(), body);
    return data;
  },
};
export const useBalance = (symbol: string, contract: string) =>
  useQuery('getBalance', () => walletApi.getBalance({ symbol, contract }), {
    retry: 0,
    cacheTime: 0,
  });

export default walletApi;
