import { setTokenHeader } from 'api';
import { makeAutoObservable } from 'mobx';
import { Account } from 'types/account';

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }
  token: any = localStorage.getItem('token');
  account: Account | null =
    localStorage.getItem('account') == null
      ? localStorage.getItem('account')
      : JSON.parse(localStorage.getItem('account') as string);
  loginTime: any = localStorage.getItem('loginTime');

  setLoginTime = (loginTime: any) => {
    this.loginTime = loginTime;
    localStorage.setItem('loginTime', loginTime);
  };

  setToken = (token: any) => {
    this.token = token;
    localStorage.setItem('token', token);
    setTokenHeader(token);
  };
  setAccount = (account: Account | null) => {
    this.account = account;
    localStorage.setItem('account', JSON.stringify(account));
  };
  reset = () => {
    this.setAccount(null);
    this.setToken('');
    this.setLoginTime(null);
  };
}

export default UserStore;
