import * as S from './styles';
import { ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import { useNavigate } from 'react-router';
import arrow from '../assets/arrow.svg';
import { REACT_APP_HOME_COLLECTION } from 'api/assets';
import ReactMarkdown from 'react-markdown';
import { getEditions, getLevel, getPrice } from 'lib/utils';
import remarkGfm from 'remark-gfm';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';

const List = ({ data }: { data: ITemplate[] }) => {
  const navigate = useNavigate();
  const lang = useLang();
  const handleAdsClick = (template_id: string) => {
    navigate(`product-detail/${REACT_APP_HOME_COLLECTION}/${template_id}`, {
      state: {
        template_id: template_id,
        collection_name: REACT_APP_HOME_COLLECTION,
      },
    });
  };

  const renderItem = (template: ITemplate, index: number) => {
    return (
      <S.RowItem
        key={template.template_id}
        onClick={(e) => {
          e.preventDefault();
          handleAdsClick(template.template_id);
        }}
      >
        <S.ImagePanel>
          <S.InfoImage src={template.immutable_data.cover} alt={''} />
        </S.ImagePanel>
        <S.DetailPanel>
          <S.DetailLabel>
            <S.InfoLabel>
              <S.LevelLabel>
                {lang.productDetail.t(langKey.pages.productDetail.level) +
                  ' ' +
                  getLevel(template.immutable_data.name)}
              </S.LevelLabel>
              <S.EditionLabel>
                {getEditions(
                  template.immutable_data.name,
                  parseInt(template.max_supply)
                ) +
                  ' ' +
                  lang.productDetail.t(langKey.pages.productDetail.editions)}
              </S.EditionLabel>
            </S.InfoLabel>
            <S.PriceLabel>
              {getPrice(template.immutable_data.name)}
            </S.PriceLabel>
          </S.DetailLabel>
          <S.DescriptionPanel>
            <S.InfoPanel>
              <S.TitleLabel>{template.immutable_data.name}</S.TitleLabel>
              <S.DescLabel>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={template.immutable_data.desc}
                ></ReactMarkdown>
              </S.DescLabel>
            </S.InfoPanel>
            <S.GoButton>
              <button
                type={'button'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAdsClick(template.template_id);
                }}
              >
                <S.GoImg src={arrow} alt={''}></S.GoImg>
              </button>
            </S.GoButton>
          </S.DescriptionPanel>
        </S.DetailPanel>
      </S.RowItem>
    );
  };

  return <S.Grid>{data.map(renderItem)}</S.Grid>;
};

export default List;
