import React, { useRef, useCallback, useState } from 'react';
import * as S from './styles';
import { useStore } from 'store';
import List from './List';
import { useNavigate } from 'react-router';
import routes from 'routes';
import ariginal from '../asset/ARIGINAL.png';
import close from '../asset/Close.png';
import { Sale } from '@bigblockchain/atomicmarket/build/API/Explorer/Types';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import { toast } from 'react-toastify';

const SelectBuy = () => {
  const { closeModal, openModal, modals } = useStore('modalsStore');
  const [selectSale, setSelectSale] = useState({} as Sale);
  const navigate = useNavigate();
  const templateId = useRef<number>(
    modals.get('selectBuy')?.templateId as number
  );
  const collectionName = useRef<string>(
    modals.get('selectBuy')?.collectionName as string
  );
  const name = useRef<string>(modals.get('selectBuy')?.name as string);
  const cover = useRef<string>(modals.get('selectBuy')?.cover as string);
  const lang = useLang();

  const onClose = useCallback(() => {
    closeModal('selectBuy');
  }, [closeModal]);

  const handleBuyClick = useCallback(() => {
    if (Object.keys(selectSale).length === 0) {
      toast.info(lang.toast.t(langKey.common.toast.selectitem));
      return;
    }
    closeModal('selectBuy');
    openModal('showDetail', {
      asset: selectSale.assets[0],
      price: selectSale.price,
      saleId: selectSale.sale_id,
    });
  }, [closeModal, openModal, selectSale, lang]);

  const handleItemClick = useCallback((item: Sale) => {
    setSelectSale(item);
  }, []);

  return (
    <S.ModalContainer width={'100vw'}>
      <S.HeaderView row centerV>
        <S.LogoImg
          src={ariginal}
          alt={''}
          onClick={() => {
            navigate(routes.home);
          }}
        ></S.LogoImg>
        <S.ClosePanel right>
          <S.UnfoldView centerV right>
            <S.MenuUnfold
              component={() => {
                return <img src={close} alt={''}></img>;
              }}
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            ></S.MenuUnfold>
          </S.UnfoldView>
        </S.ClosePanel>
      </S.HeaderView>
      <S.Content>
        <S.LabelPanel>
          <img src={cover.current} alt={''}></img>
          <S.ContentLabel>
            <S.Title>{name.current}</S.Title>
            <S.SelectLabel>
              {lang.modal.t(langKey.common.modal.selectsearial)}
            </S.SelectLabel>
            <S.ClickLabel>
              {' '}
              {lang.modal.t(langKey.common.modal.clicktobuy)}
            </S.ClickLabel>
          </S.ContentLabel>
        </S.LabelPanel>
        <List
          templateId={templateId.current}
          collectionName={collectionName.current}
          handleItemClick={handleItemClick}
        ></List>
        <S.ConfirmPanel center>
          <S.Confirm onClick={handleBuyClick}>
            {lang.modal.t(langKey.common.modal.buy)}
          </S.Confirm>
        </S.ConfirmPanel>
      </S.Content>
    </S.ModalContainer>
  );
};

export default SelectBuy;
