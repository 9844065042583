import React, { useMemo, useState } from 'react';
import * as S from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { useLang } from 'i18n/i18n.constants';
import { langKey } from 'i18n/i18n.valuekey';
import routes from 'routes';
import logo from './asset/Logo.png';
import close from './asset/Close.png';
import ariginal from './asset/ARIGINAL.png';
import View from 'components/UI/View';

const GlobalHeader = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const { reset, account } = useStore('userStore');
  const { openModal } = useStore('modalsStore');
  const lang = useLang();
  const location = useLocation();

  const showMenu = useMemo(() => {
    return (
      location.pathname !== routes.login &&
      location.pathname !== routes.register
    );
  }, [location.pathname]);

  const handleButtonClick = (e: any) => {
    if (e.key === 'logout') {
      reset();
      navigate(routes.login);
    } else if (e.key === 'home') {
      navigate(routes.home);
    } else if (e.key === 'profile') {
      navigate(routes.profile);
    } else if (e.key === 'about') {
      // navigate(routes.about);
      window.open('https://ariginal.one');
    } else if (e.key === 'deposit') {
      handleToDeposti();
    }

    setToggle(true);
  };

  const handleToDeposti = () => {
    openModal('showDeposit');
  };

  return (
    <S.HeaderContainer>
      {toggle && (
        <S.HeaderView row centerV>
          <S.LogoImg
            src={ariginal}
            alt={''}
            onClick={() => {
              navigate(routes.home);
              setToggle(true);
            }}
          ></S.LogoImg>
          <S.MenuPanel right>
            <View
              row
              centerV
              onClick={(e) => {
                if (showMenu) {
                  e.preventDefault();
                  setToggle(false);
                }
              }}
            >
              {showMenu && (
                <S.MenuTitle>
                  {lang.menu.t(langKey.common.menu.menu)}
                </S.MenuTitle>
              )}
              <S.MenuFold
                component={() => <img src={logo} alt={''}></img>}
              ></S.MenuFold>
            </View>
          </S.MenuPanel>
        </S.HeaderView>
      )}
      {!toggle && (
        <S.MenuContainer width={'100vw'}>
          <S.HeaderView row centerV>
            <S.LogoImg
              src={ariginal}
              alt={''}
              onClick={() => {
                navigate(routes.home);
                setToggle(true);
              }}
            ></S.LogoImg>
            <S.MenuPanel right>
              <S.UnfoldView centerV right>
                <S.MenuUnfold
                  component={() => <img src={close} alt={''}></img>}
                  onClick={(e) => {
                    e.preventDefault();
                    setToggle(true);
                  }}
                ></S.MenuUnfold>
              </S.UnfoldView>
            </S.MenuPanel>
          </S.HeaderView>
          <S.MenuView center>
            <S.MenuCotainer onClick={handleButtonClick}>
              <S.MenuItem key="home">
                {lang.menu.t(langKey.common.menu.home)}
              </S.MenuItem>
              <S.MenuItem key="profile">
                {lang.menu.t(langKey.common.menu.profile)}
              </S.MenuItem>
              <S.MenuItem key="about">
                {lang.menu.t(langKey.common.menu.about)}
              </S.MenuItem>
              <S.MenuItem key="deposit">
                {lang.menu.t(langKey.common.menu.deposit)}
              </S.MenuItem>
              <S.MenuItemLogout key="logout">
                {account ? account.email : ''}
              </S.MenuItemLogout>
            </S.MenuCotainer>
          </S.MenuView>
        </S.MenuContainer>
      )}
    </S.HeaderContainer>
  );
};

export default GlobalHeader;
