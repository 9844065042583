import styled from 'styled-components';
import View from 'components/UI/View';
import Modal from 'components/UI/Modal';
import Icon from '@ant-design/icons';

export const ModalContainer = styled(Modal)`
  width: 100vw;
  filter: blur(20px);
  overflow: hidden;
`;

ModalContainer.defaultProps = {
  style: {
    border: 'none',
    borderRadius: 0,
  },
};

export const UnfoldView = styled(View)`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    height: 5.47vh;
  }

  @media (max-width: 767px) {
    height: 4.59vh;
  }
`;

export const MenuUnfold = styled(Icon)`
  opacity: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    img {
      width: 1.25vw;
    }
  }

  @media (max-width: 767px) {
    img {
      width: 4.8vw;
    }
  }
`;

export const HeaderView = styled(View)`
  @media (min-width: 768px) {
    padding-top: 3.9vh;
    padding-left: 2.78vw;
    padding-right: 2.78vw;
  }

  @media (max-width: 767px) {
    padding-top: 2.04vh;
    padding-left: 4.27vw;
    padding-right: 4.27vw;
  }
`;

export const ClosePanel = styled(View)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const LogoImg = styled.img`
  cursor: pointer;
  width: 100%;
  @media (min-width: 768px) {
    width: 5.14vw;
  }

  @media (max-width: 767px) {
    width: 19.73vw;
  }
`;

export const Content = styled(View)`
  @media (min-width: 768px) {
    padding-left: 38.33vw;
    padding-right: 38.33vw;
  }

  @media (max-width: 767px) {
  }
`;

export const Title = styled.div`
  font-weight: 600;
  opacity: 1;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.17vh;
    margin-bottom: 4.1vh;
    margin-top: 37.3vh;
  }

  @media (max-width: 767px) {
    font-size: 1.53vh;
    margin-top: 27vh;
    margin-bottom: 4.84vh;
  }
`;

export const Cover = styled.div`
  text-align: center;
  min-height: 208px;
`;

export const Description = styled.div`
  position: relative;
  @media (min-width: 768px) {
    margin-top: 3.9vh;
    font-size: 1.37vh;
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
    font-size: 1.78vh;
  }
`;
export const Border = styled.div`
  background-color: #fff;
  height: 1px;
`;

export const ContentText = styled.div`
  flex: 2;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const ContentPrice = styled.div`
  flex: 4;
  text-align: right;
  @media (min-width: 768px) {
    padding-right: 1.67vw;
  }

  @media (max-width: 767px) {
    padding-right: 6.4vw;
  }
`;

export const CopyPanel = styled(View)`
  @media (min-width: 768px) {
    padding: 2.44vh 1.17vw;
  }

  @media (max-width: 767px) {
    padding: 3.06vh 4.27vw;
  }
`;
export const Copy = styled.button`
  color: #fff;
  flex: 1;
  padding: 0;
  margin: 0;
  opacity: 0.5;
  font-weight: 600;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;
