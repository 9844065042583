export const langKey = {
  pages: {
    login: {
      username: 'username',
      password: 'password',
      login: 'login',
      register: 'register',
    },
    register: {
      email: 'email',
      verify: 'verify',
      verifyCode: 'verifyCode',
      password: 'password',
      repeatpassword: 'repeatpassword',
      register: 'register',
      countdown: 's',
    },
    home: {
      title: 'title',
      subtitle: 'subtitle',
      subtitle1: 'subtitle1',
      subtitle2: 'subtitle2',
      website: 'website',
      contact: 'contact',
      social: 'social',
      copyright: 'copyright',
    },
    productDetail: {
      selectBuyTitle: 'selectBuyTitle',
      buy: 'Buy',
      selectAndBuy: 'selectAndBuy',
      confirmOrder: 'confirmOrder',
      serial: 'serial',
      gotocollection: 'gotocollection',
      editions: 'editions',
      level: 'level',
    },
    profile: {
      collection: 'collection',
      withdraw: 'withdraw',
      editions: 'editions',
    },
    withdraw: {
      symbol: 'symbol',
      from: 'from',
      amount: 'amount',
      receiver: 'receiver',
      memo: 'memo',
      chainType: 'chaintype',
      withdraw: 'withdraw',
    },
    profileEdit: {
      nickname: 'nickname',
      avatar: 'avatar',
      edit: 'edit',
    },
  },
  common: {
    menu: {
      about: 'about',
      profile: 'profile',
      home: 'home',
      deposit: 'deposit',
      menu: 'menu',
    },
    toast: {
      requsterror: 'requsterror',
      pwdnotequal: 'pwdnotequal',
      emailempty: 'emailempty',
      copysuccess: 'copysuccess',
      noenoughbalance: 'noenoughbalance',
      withdrawsuccess: 'withdrawsuccess',
      selectitem: 'selectitem',
    },
    modal: {
      confirm: 'confirm',
      depositinfo: 'depositinfo',
      selectsearial: 'selectsearial',
      clicktobuy: 'clicktobuy',
      buy: 'buy',
      receiver: 'receiver',
      copy: 'copy',
      memo: 'memo',
      limit: 'limit',
    },
  },
};
