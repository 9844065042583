import { useEffect, useRef, useState } from 'react';
import * as S from './styles';

interface TextScrollProp {
  text?: string;
  speed?: number;
}

const Horizontal = (prop: TextScrollProp) => {
  const [duration, setDuration] = useState(5000);
  const [contentWidth, setContentWidth] = useState(500);
  const [containerWidth, setContainerWidth] = useState(500);
  const contentRef = useRef<any>(null);

  useEffect(() => {
    let parentElement = contentRef.current.parentElement;
    let children = contentRef.current.children;
    let containerWidth = parentElement ? parentElement.clientWidth : 500;
    let contentWidth = 0;
    let contentExactWidth = 0;
    if (children && children.length > 0) {
      // for (let i = 0; i < children.length; i++) {
      contentExactWidth += children[0].scrollWidth;
      contentWidth += children[0].scrollWidth;
      // }
    }

    // 判断文字框长度
    if (containerWidth > contentExactWidth) {
      setDuration(0);
    } else {
      contentWidth = contentWidth === 0 ? 500 : contentWidth;
      // contentWidth += containerWidth;
      setContainerWidth(containerWidth);
      setContentWidth(contentWidth);
      let duration = ((prop.speed || 5000) * contentWidth) / 500000;
      setDuration(duration);
    }
  }, [contentRef, prop.speed]);
  return (
    <S.StyledHorizontal>
      {duration > 0 && (
        <S.Container
          ref={contentRef}
          constainer_width={containerWidth}
          content_width={contentWidth}
          duration={duration}
        >
          {[prop.text, prop.text].map((e: any, i: number) => {
            return (
              <S.Item
                key={i}
                title={prop.text}
                constainer_width={containerWidth}
              >
                {e}
              </S.Item>
            );
          })}
        </S.Container>
      )}

      {duration === 0 && prop.text}
    </S.StyledHorizontal>
  );
};

export default Horizontal;
