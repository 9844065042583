import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as S from './styles';
import apiMarket from 'api/market';
import {
  SaleState,
  SortOrder,
  SaleSort,
  Sale,
} from '@bigblockchain/atomicmarket/build/API/Explorer/Types';
import { asset, symbol } from 'eos-common';
import { Spin } from 'antd';
import { replaceSymbol } from 'lib/utils';

const List = ({
  templateId,
  collectionName,
  handleItemClick,
}: {
  templateId: number;
  collectionName: string;
  handleItemClick: (item: Sale) => void;
}) => {
  const [sales, setSales] = useState([] as Sale[]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize] = useState(10);
  const [activeId, setActiveId] = useState(-1);
  const throttling = useRef(false);

  const renderItem = (sale: Sale, index: number) => {
    return (
      <S.RowPanel
        key={index}
        isActive={activeId === index}
        onClick={(e) => {
          e.preventDefault();
          setActiveId(index);
          handleItemClick(sale);
        }}
      >
        <S.Row row center>
          <S.Serial>{'#' + sale.assets[0]?.template_mint}</S.Serial>
          <S.Price>
            {replaceSymbol(
              asset(
                BigInt(sale?.price?.amount),
                symbol(sale?.price?.token_symbol, sale?.price?.token_precision)
              ).toString()
            )}
          </S.Price>
        </S.Row>
        <S.Border></S.Border>
      </S.RowPanel>
    );
  };

  const fetchData = useCallback(async () => {
    apiMarket
      .getSales(
        {
          template_id: templateId,
          collection_name: collectionName,
          state: [SaleState.Listed],
          sort: SaleSort.Mint,
          order: SortOrder.Asc,
        },
        page,
        pageSize
      )
      .then((data) => {
        if (data.length < pageSize) {
          setHasMore(false);
        }
        const temp = sales.concat(data);
        setSales(temp);
        setPage(page + 1);
      });
  }, [collectionName, page, pageSize, sales, templateId]);

  useEffect(() => {
    setPage(1);
    setSales([]);
    setHasMore(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInfiniteOnLoad = () => {
    if (throttling.current) {
      return;
    }
    throttling.current = true;
    setTimeout(() => {
      throttling.current = false;
      fetchData();
    }, 200);
  };

  return (
    <S.ListPanel hasMore={hasMore}>
      <S.Scroller
        initialLoad={false}
        useWindow={false}
        pageStart={1}
        threshold={250}
        loadMore={handleInfiniteOnLoad}
        hasMore={hasMore}
        loader={
          <S.Loading center key={0}>
            <Spin indicator={<S.LoadingIcon spin />} />
          </S.Loading>
        }
      >
        <S.Border></S.Border>
        <S.Grid>{sales.map(renderItem)}</S.Grid>
      </S.Scroller>
    </S.ListPanel>
  );
};

export default List;
