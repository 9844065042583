import React from 'react';
import { observer } from 'mobx-react-lite';
import * as S from './styles';
import { Spin } from 'antd';
import { useStore } from 'store';
import FadeModal from 'components/UI/FadeModal';
import { useWindowSize } from 'react-use';

const Loading = () => {
  const { loading } = useStore('loadingStore');
  const { height: innerHeight } = useWindowSize();
  return (
    <FadeModal isOpen={loading}>
      <S.ModalContainer
        width={'100vw'}
        style={{
          margin: 0,
          border: 'none',
          borderRadius: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          height: innerHeight + 'px',
          // filter: blur(20),
        }}
      >
        <S.Loading center>
          <Spin indicator={<S.LoadingIcon spin />} />
        </S.Loading>
      </S.ModalContainer>
    </FadeModal>
  );
};

export default observer(Loading);
