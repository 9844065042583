import View from 'components/UI/View';
import styled from 'styled-components';

export const Content = styled(View)`
  opacity: 1;
  height: 100%;
`;

export const TitlePanel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 26.95vh;
    margin-top: 10.94vh;
  }

  @media (max-width: 767px) {
    margin-top: 10.19vh;
    margin-left: 4.27vw;
  }
`;

export const Title = styled.div`
  white-space: pre-line;

  @media (min-width: 768px) {
    margin-left: 2.78vw;
    flex: 1;
    font-size: 14.65vh;
  }

  @media (max-width: 767px) {
    font-size: 8.15vh;
  }
`;

export const SubTitle = styled.div`
  white-space: pre-line;
  position: relative;
  color: rgba(255, 255, 255, 0.75);
  @media (min-width: 768px) {
    display: flex;
    width: 31.53vw;
    font-size: 1.46vh;
    line-height: 1.95vh;
    align-items: center;
    justify-content: center;
    margin-right: 3.4vw;
    p {
      margin: auto 0;
    }
    span {
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }

  @media (max-width: 767px) {
    padding-top: 4.59vh;
    font-size: 1.53vh;
    line-height: 2.04vh;
    padding-right: 13.87vw;
    margin-bottom: 10.19vh;

    span {
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
  }
`;

export const Grid = styled.ul`
  display: grid;
  margin-block-end: 0;
  border-top: 1px solid #fff;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    justify-content: center;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
  }
`;

export const RowItem = styled.li`
  cursor: pointer;
  @media (min-width: 768px) {
    position: relative;
    min-height: 74.11vh;
    border-bottom: 1px solid #fff;
    &:nth-child(odd) {
      border-right: 1px solid #fff;
    }
  }

  @media (max-width: 767px) {
    border-bottom: 1px solid #fff;
  }
`;

export const ImagePanel = styled.div`
  @media (min-width: 768px) {
    // margin: 2.78vw 3.89vw 8.89vw 3.19vw;
  }

  @media (max-width: 767px) {
    padding: 1.91vh 0;
    height: 39.49vh;
  }
`;
export const InfoImage = styled.img`
  @media (min-width: 768px) {
    width: 100%;
    min-height: 74.11vh;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(
      #ffffff calc(100% - 25.22vh),
      transparent
    );
  }

  @media (max-width: 767px) {
    width: 100%;
    min-height: 39.49vh;
    object-fit: cover;
  }
`;

export const DetailPanel = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    bottom: 3.91vh;
    min-height: 18.34vh;
    padding-left: 2.64vw;
    padding-right: 2.78vw;
    z-index: 99;
    filter: blur(0px);
  }

  @media (max-width: 767px) {
    padding-left: 4.27vw;
    padding-right: 4.27vw;
    margin-top: 1.66vh;
  }
`;
export const DetailLabel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    font-size: 1.17vh;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    font-size: 1.53vh;
  }
`;

export const InfoLabel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
  }
`;
export const LevelLabel = styled.div`
  opacity: 0.5;
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;
export const EditionLabel = styled.div`
  @media (min-width: 768px) {
    margin-left: 1.11vw;
  }

  @media (max-width: 767px) {
    margin-left: 2.67vw;
  }
`;

export const PriceLabel = styled.div`
  @media (min-width: 768px) {
    margin-left: 1.11vw;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    flex: 1;
    text-align: right;
  }
`;

export const DescriptionPanel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 2.34vh;
  }

  @media (max-width: 767px) {
    margin-top: 2.04vh;
    display: flex;
    flex-direction: row;
  }
`;

export const InfoPanel = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  @media (max-width: 767px) {
    flex: 1;
  }
`;

export const TitleLabel = styled.div`
  @media (min-width: 768px) {
    font-size: 3.125vh;
    max-width: 12.5vw;
  }

  @media (max-width: 767px) {
    font-size: 4.08vh;
  }
`;

export const DescLabel = styled.div`
  white-space: pre-line;
  opacity: 0.75;
  flex: 1;
  color: rgba(255, 255, 255, 0.75);
  ul {
    list-style: disc;
    list-style-position: inside;
    font-size: 0;
    li {
      @media (min-width: 768px) {
        line-height: 1.56vh;
        font-size: 1.17vh;
        text-indent: -17px;
        padding-left: 24px;
      }

      @media (max-width: 767px) {
        line-height: 2.04vh;
        font-size: 1.53vh;
        text-indent: -17px;
        padding-left: 24px;
      }
    }
  }
  @media (min-width: 768px) {
    padding-right: 3.82vw;
    padding-left: 6.46vw;
  }

  @media (max-width: 767px) {
    margin-top: 3.06vh;
    font-weight: 400;
    font-size: 1.53vh;
    margin-bottom: 5.1vh;
  }
`;

export const GoButton = styled.div`
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }
`;

export const GoImg = styled.img`
  @media (min-width: 768px) {
    width: 2.08vw;
  }

  @media (max-width: 767px) {
    width: 6.93vw;
  }
`;

export const FooterPannel = styled(View)`
  text-align: center;
  @media (min-width: 768px) {
    margin-top: 11.23vh;
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
  }
`;

export const FooterTitle = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 14.65vh;
  }

  @media (max-width: 767px) {
    font-size: 8.15vh;
  }
`;

export const Logo = styled.div`
  @media (min-width: 768px) {
    margin-top: 5.86vh;
    img {
      width: 3.89vw;
    }
  }

  @media (max-width: 767px) {
    margin-top: 5.1vh;
    img {
      height: 7.13vh;
    }
  }
`;

export const Copyright = styled.div`
  @media (min-width: 768px) {
    margin-top: 4vh;
    padding-bottom: 8.2vh;
  }

  @media (max-width: 767px) {
    font-weight: 400;
    font-size: 2.04vh;
    margin-top: 5.1vh;
    padding-bottom: 8.66vh;
  }
`;
